import { z } from 'zod'
import { Position } from '@rio-cloud/rio-uikit/mapTypes'
import { AlertSchema, VehicleSchema } from 'APIzod'

export const UuidSchema = z.string().uuid()
export const NonEmptyString = z.string().min(1)
export const OptionalNonEmptyString = z.string().min(1).optional()
export const OptionalNumber = z.number().optional()
export const DateTimeStringSchema = z.string().datetime()
export const NonNegativeNumber = z.number().min(0)
export const RioPositionSchema: z.ZodType<Position> = z.object({
  lat: z.number(),
  lng: z.number()
})

export const HEREResultType = z.enum([
  'place',
  'locality',
  'street',
  'houseNumber',
  'administrativeArea',
  'addressBlock',
  'intersection',
  'postalCodePoint',
  'chainQuery',
  'categoryQuery'
])
export const MapViewSchema = z.object({
  west: OptionalNumber,
  south: OptionalNumber,
  east: OptionalNumber,
  north: OptionalNumber
})

export const AddressSchema = z.object({
  label: OptionalNonEmptyString,
  countryCode: OptionalNonEmptyString,
  countryName: OptionalNonEmptyString,
  state: OptionalNonEmptyString,
  county: OptionalNonEmptyString,
  city: OptionalNonEmptyString,
  district: OptionalNonEmptyString,
  street: OptionalNonEmptyString,
  postalCode: OptionalNonEmptyString,
  houseNumber: OptionalNonEmptyString
})

export const HEREAddressItemSchema = z.object({
  title: OptionalNonEmptyString,
  id: OptionalNonEmptyString,
  resultType: HEREResultType.optional(),
  houseNumberType: OptionalNonEmptyString,
  address: AddressSchema.optional(),
  position: RioPositionSchema,
  access: z.array(RioPositionSchema).optional(),
  distance: OptionalNumber,
  mapView: MapViewSchema.optional()
})

export const HereReverseGeocodeResponseSchema = z.object({
  items: z.array(HEREAddressItemSchema).optional()
})

export const AlertWithVINSchema = AlertSchema.extend({
  vin: z.string().min(1)
})

export const ExtendedVehicleSchema = VehicleSchema.extend({ alerts: z.array(AlertWithVINSchema) })
