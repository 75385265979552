import { Position } from '@rio-cloud/rio-uikit/mapTypes'
import { RioPositionSchema } from 'helper/zod'

type WithVin = { vin: string }
type WithId = { id: string }
type WithIdentifier = WithId | WithVin
export type GenericRecord<T extends WithIdentifier> = Record<string, T>

export const normalizeRecordData = <T extends WithIdentifier>(data?: GenericRecord<T>): T[] => {
  if (!data) {
    return []
  }
  return Object.values(data)
}

export const denormalizeListWithVin = <T extends WithVin>(list: T[]): GenericRecord<T> => {
  return list.reduce((pre: GenericRecord<T>, cur) => {
    return { ...pre, [cur.vin]: cur }
  }, {})
}

export const denormalizeListWithId = <T extends WithId>(list: T[]): GenericRecord<T> => {
  return list.reduce((pre: GenericRecord<T>, cur) => {
    return { ...pre, [cur.id]: cur }
  }, {})
}

export const buildReverseGeocodeParams = (position: Position | undefined) => {
  const response = RioPositionSchema.safeParse(position)
  if (!response.success) {
    console.error('[buildReverseGeocodeParams]: the Position is not defined accordingly', {
      position,
      error: response.error
    })
    return undefined
  }
  const { lat, lng } = response.data
  return `${lat},${lng}`
}
