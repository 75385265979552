import Map from 'features/map/Map'
import { useListHubsQuery } from 'services/hubs'
import { useOnUpdateVehicleSubscriptionQuery } from 'services/vehicles'
import { normalizeRecordData } from 'services/utils'
import PrioritizedAlertList from 'features/prioritized-alerts/PrioritizedAlertList'
import Clock from 'components/Clock'
import { useClock } from 'hooks/useClock'
import { prioritizeVehicleAlerts } from 'features/prioritized-alerts/utils'
import { useSelector } from 'react-redux'
import { getSelectedVIN } from 'store/slices/appSlice'
import ErrorBoundary from 'components/ErrorBoundary'
import ErrorFallback from 'components/ErrorFallback'
import { getVehicleByVin } from 'helper/vehicle'
import useMultimonitorSetup from '../hooks/useMultimonitorSetup'
import { useOnMissionSubscriptionQuery } from 'services/mission'
import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'

const MainPage = () => {
  const { data: hubsData, error: listHubsError } = useListHubsQuery('ListHubs', {
    refetchOnMountOrArgChange: true
  })
  const selectedVIN = useSelector(getSelectedVIN)

  const {
    data: vehiclesData,
    error: onUpdatedVehicleError,
    alerts,
    isLoading,
    isEmpty
  } = useOnUpdateVehicleSubscriptionQuery('ListVehicles', {
    refetchOnMountOrArgChange: true,
    selectFromResult: (state) => {
      const prioritizedList = prioritizeVehicleAlerts(state.data)
      return {
        ...state,
        isEmpty: prioritizedList?.errors.length === 0 && prioritizedList?.warnings.length === 0,
        alerts: prioritizedList
      }
    }
  })
  const selectedVehicle = useMemo(
    () => (selectedVIN ? getVehicleByVin(vehiclesData, selectedVIN) : undefined),
    [selectedVIN, vehiclesData]
  )

  const { data: missionData } = useOnMissionSubscriptionQuery(
    selectedVehicle?.activeMissionId ?? skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  )

  useMultimonitorSetup()

  const datetime = useClock()

  return (
    <main className="flex h-full" data-testid="main-page-content">
      <div className="absolute left-15 top-15 z-10">
        <Clock datetime={datetime} />
      </div>
      <div className="h-full flex-1 overflow-y-hidden">
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          error={listHubsError}
          containerClassName="mt-[70px] mx-10"
        >
          <Map
            activeMission={missionData}
            vehicles={normalizeRecordData(vehiclesData)}
            hubs={hubsData}
            alerts={alerts?.errors.concat(alerts.warnings)}
            selectedVehicle={selectedVehicle}
          />
        </ErrorBoundary>
      </div>
      <div className="h-full min-w-[400px] max-w-[500px] basis-1/3 bg-white px-50 pt-50">
        <ErrorBoundary FallbackComponent={ErrorFallback} error={onUpdatedVehicleError}>
          <PrioritizedAlertList
            errors={alerts?.errors}
            warnings={alerts?.warnings}
            isLoading={isLoading}
            isEmpty={isEmpty}
          />
        </ErrorBoundary>
      </div>
    </main>
  )
}
export default MainPage
