/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY DIRECTLY.
 */
import { z } from 'zod'
import {
  VehicleCommandInput,
  UpdatedGeneratedRouteInput,
  Severity,
  Route,
  PositionInput,
  UpdateHubInput,
  Position,
  OwnerInput,
  Owner,
  MissionStateValue,
  MissionStateInput,
  MissionState,
  MissionProgressDetailsInput,
  MissionEventType,
  MissionEventInput,
  MissionEvent,
  MissionProgressDetails,
  Hub,
  GeneratedRoute,
  DrivingState,
  CreateHubInput,
  Status,
  Reason,
  ConnectivityStatusInput,
  ConnectivityStatus,
  CommandResponse,
  ExecutionState,
  Command,
  CommandInfoInput,
  CommandInfo,
  AlertInput,
  Alert,
  AdsState,
  Vehicle,
  UpdatedVehicleInput,
  UpdatedMissionInput,
  Mission
} from './API'

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>
}>

type definedNonNullAny = {}

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v))

export const SeveritySchema = z.nativeEnum(Severity)

export const MissionStateValueSchema = z.nativeEnum(MissionStateValue)

export const MissionEventTypeSchema = z.nativeEnum(MissionEventType)

export const DrivingStateSchema = z.nativeEnum(DrivingState)

export const StatusSchema = z.nativeEnum(Status)

export const ReasonSchema = z.nativeEnum(Reason)

export const ExecutionStateSchema = z.nativeEnum(ExecutionState)

export const CommandSchema = z.nativeEnum(Command)

export const AdsStateSchema = z.nativeEnum(AdsState)

export const VehicleCommandInputSchema: z.ZodObject<Properties<VehicleCommandInput>> = z.object({
  command: CommandSchema,
  vin: z.string().min(1)
})

export const UpdatedGeneratedRouteInputSchema: z.ZodObject<Properties<UpdatedGeneratedRouteInput>> =
  z.object({
    createdAt: z.string().datetime(),
    durationInSeconds: z.number(),
    externalRouteId: z.string().min(1),
    landingHubId: z.string().uuid().min(1),
    launchHubId: z.string().uuid().min(1),
    predefinedRouteId: z.string().uuid().min(1),
    totalDistanceInMeters: z.number(),
    updatedAt: z.string().datetime(),
    waypoints: z.array(z.lazy(() => PositionInputSchema))
  })

export const PositionInputSchema: z.ZodObject<Properties<PositionInput>> = z.object({
  altitude: z.number().nullish(),
  heading: z.number().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish()
})

export const UpdateHubInputSchema: z.ZodObject<Properties<UpdateHubInput>> = z.object({
  active: z.boolean().nullish(),
  id: z.string().uuid().min(1),
  name: z.string().min(1),
  position: z.lazy(() => PositionInputSchema.nullish())
})

export const PositionSchema: z.ZodObject<Properties<Position>> = z.object({
  __typename: z.literal('Position').optional(),
  altitude: z.number().nullish(),
  heading: z.number().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish()
})

export const OwnerInputSchema: z.ZodObject<Properties<OwnerInput>> = z.object({
  email: z.string().min(1),
  fullName: z.string().min(1),
  id: z.string().uuid().min(1),
  phoneNumber: z.string().min(1),
  position: z.string().min(1)
})

export const OwnerSchema: z.ZodObject<Properties<Owner>> = z.object({
  __typename: z.literal('Owner').optional(),
  email: z.string().min(1),
  fullName: z.string().min(1),
  id: z.string().uuid().min(1),
  phoneNumber: z.string().min(1),
  position: z.string().min(1)
})

export const MissionStateInputSchema: z.ZodObject<Properties<MissionStateInput>> = z.object({
  updatedAt: z.string().datetime(),
  value: MissionStateValueSchema
})

export const MissionStateSchema: z.ZodObject<Properties<MissionState>> = z.object({
  __typename: z.literal('MissionState').optional(),
  updatedAt: z.string().datetime(),
  value: MissionStateValueSchema
})

export const MissionProgressDetailsInputSchema: z.ZodObject<
  Properties<MissionProgressDetailsInput>
> = z.object({
  eta: z.string().datetime(),
  remainingDistanceInMeters: z.number(),
  traveledDistanceInMeters: z.number(),
  updatedAt: z.string().datetime()
})

export const MissionEventInputSchema: z.ZodObject<Properties<MissionEventInput>> = z.object({
  createdAt: z.string().datetime(),
  position: z.lazy(() => PositionInputSchema),
  type: MissionEventTypeSchema
})

export const MissionEventSchema: z.ZodObject<Properties<MissionEvent>> = z.object({
  __typename: z.literal('MissionEvent').optional(),
  createdAt: z.string().datetime(),
  position: PositionSchema,
  type: MissionEventTypeSchema
})

export const MissionProgressDetailsSchema: z.ZodObject<Properties<MissionProgressDetails>> =
  z.object({
    __typename: z.literal('MissionProgressDetails').optional(),
    eta: z.string().datetime(),
    remainingDistanceInMeters: z.number(),
    traveledDistanceInMeters: z.number(),
    updatedAt: z.string().datetime()
  })

export const HubSchema: z.ZodObject<Properties<Hub>> = z.object({
  __typename: z.literal('Hub').optional(),
  active: z.boolean(),
  address: z.string().nullish(),
  createdAt: z.string().datetime(),
  id: z.string().uuid().min(1),
  name: z.string().min(1),
  position: PositionSchema,
  updatedAt: z.string().datetime()
})

export const RouteSchema: z.ZodObject<Properties<Route>> = z.object({
  __typename: z.literal('Route').optional(),
  averageDuration: z.number(),
  createdAt: z.string().datetime(),
  distance: z.number(),
  id: z.string().uuid().min(1),
  landingHub: HubSchema,
  landingHubId: z.string().uuid().min(1),
  launchHub: HubSchema,
  launchHubId: z.string().uuid().min(1),
  updatedAt: z.string().datetime(),
  waypoints: z.array(PositionSchema)
})

export const GeneratedRouteSchema: z.ZodObject<Properties<GeneratedRoute>> = z.object({
  __typename: z.literal('GeneratedRoute').optional(),
  createdAt: z.string().datetime(),
  durationInSeconds: z.number(),
  externalRouteId: z.string().min(1),
  landingHub: HubSchema,
  landingHubId: z.string().uuid().min(1),
  launchHub: HubSchema,
  launchHubId: z.string().uuid().min(1),
  predefinedRouteId: z.string().uuid().min(1),
  totalDistanceInMeters: z.number(),
  updatedAt: z.string().datetime(),
  waypoints: z.array(PositionSchema)
})

export const CreateHubInputSchema: z.ZodObject<Properties<CreateHubInput>> = z.object({
  active: z.boolean(),
  name: z.string().min(1),
  position: z.lazy(() => PositionInputSchema)
})

export const ConnectivityStatusInputSchema: z.ZodObject<Properties<ConnectivityStatusInput>> =
  z.object({
    description: z.string().nullish(),
    reason: ReasonSchema.nullish(),
    type: StatusSchema.nullish()
  })

export const ConnectivityStatusSchema: z.ZodObject<Properties<ConnectivityStatus>> = z.object({
  __typename: z.literal('ConnectivityStatus').optional(),
  description: z.string().nullish(),
  reason: ReasonSchema.nullish(),
  type: StatusSchema.nullish()
})

export const CommandResponseSchema: z.ZodObject<Properties<CommandResponse>> = z.object({
  __typename: z.literal('CommandResponse').optional(),
  command: CommandSchema,
  errorMessage: z.string().nullish(),
  invocationId: z.string().uuid().min(1),
  isSuccess: z.boolean(),
  receivedByVehicleAt: z.string().datetime(),
  sentToVehicleAt: z.string().datetime(),
  vin: z.string().min(1)
})

export const CommandInfoInputSchema: z.ZodObject<Properties<CommandInfoInput>> = z.object({
  command: CommandSchema.nullish(),
  errorMessage: z.string().nullish(),
  executionState: ExecutionStateSchema.nullish(),
  lastUpdatedAt: z.string().datetime()
})

export const CommandInfoSchema: z.ZodObject<Properties<CommandInfo>> = z.object({
  __typename: z.literal('CommandInfo').optional(),
  command: CommandSchema.nullish(),
  errorMessage: z.string().nullish(),
  executionState: ExecutionStateSchema.nullish(),
  lastUpdatedAt: z.string().datetime()
})

export const AlertInputSchema: z.ZodObject<Properties<AlertInput>> = z.object({
  category: z.string().min(1),
  description: z.string().min(1),
  id: z.string().uuid().min(1),
  sentAt: z.string().datetime(),
  severity: SeveritySchema
})

export const AlertSchema: z.ZodObject<Properties<Alert>> = z.object({
  __typename: z.literal('Alert').optional(),
  category: z.string().min(1),
  description: z.string().min(1),
  id: z.string().uuid().min(1),
  sentAt: z.string().datetime(),
  severity: SeveritySchema
})

export const VehicleSchema: z.ZodObject<Properties<Vehicle>> = z.object({
  __typename: z.literal('Vehicle').optional(),
  activeMissionId: z.string().uuid().nullish(),
  adsState: AdsStateSchema,
  alerts: z.array(AlertSchema),
  commandInfo: CommandInfoSchema.nullish(),
  createdAt: z.string().datetime(),
  customerId: z.string().uuid().nullish(),
  drivingState: DrivingStateSchema,
  externalId: z.string().uuid().min(1),
  latestVehicleUpdate: z.string().datetime().nullish(),
  licensePlate: z.string().nullish(),
  name: z.string().nullish(),
  owners: z.array(OwnerSchema),
  position: PositionSchema.nullish(),
  speedInKmh: z.number().nullish(),
  status: ConnectivityStatusSchema.nullish(),
  updatedAt: z.string().datetime(),
  vin: z.string().min(1),
  weightInKg: z.number().nullish()
})

export const UpdatedVehicleInputSchema: z.ZodObject<Properties<UpdatedVehicleInput>> = z.object({
  activeMissionId: z.string().uuid().nullish(),
  adsState: AdsStateSchema,
  alerts: z.array(z.lazy(() => AlertInputSchema)),
  commandInfo: z.lazy(() => CommandInfoInputSchema.nullish()),
  createdAt: z.string().datetime(),
  customerId: z.string().uuid().nullish(),
  drivingState: DrivingStateSchema,
  externalId: z.string().uuid().min(1),
  latestVehicleUpdate: z.string().datetime().nullish(),
  licensePlate: z.string().nullish(),
  name: z.string().nullish(),
  owners: z.array(z.lazy(() => OwnerInputSchema)),
  position: z.lazy(() => PositionInputSchema.nullish()),
  speedInKmh: z.number().nullish(),
  status: z.lazy(() => ConnectivityStatusInputSchema.nullish()),
  updatedAt: z.string().datetime(),
  vin: z.string().min(1),
  weightInKg: z.number().nullish()
})

export const UpdatedMissionInputSchema: z.ZodObject<Properties<UpdatedMissionInput>> = z.object({
  actualArrivalTime: z.string().datetime().nullish(),
  actualStartTime: z.string().datetime().nullish(),
  createdAt: z.string().datetime(),
  description: z.string().nullish(),
  events: z.array(z.lazy(() => MissionEventInputSchema)).nullish(),
  id: z.string().uuid().min(1),
  name: z.string().min(1),
  plannedArrivalTime: z.string().datetime().nullish(),
  progressDetails: z.lazy(() => MissionProgressDetailsInputSchema.nullish()),
  route: z.lazy(() => UpdatedGeneratedRouteInputSchema.nullish()),
  state: z.lazy(() => MissionStateInputSchema),
  updatedAt: z.string().datetime(),
  vin: z.string().min(1)
})

export const MissionSchema: z.ZodObject<Properties<Mission>> = z.object({
  __typename: z.literal('Mission').optional(),
  actualArrivalTime: z.string().datetime().nullish(),
  actualStartTime: z.string().datetime().nullish(),
  createdAt: z.string().datetime(),
  description: z.string().nullish(),
  events: z.array(MissionEventSchema).nullish(),
  id: z.string().uuid().min(1),
  name: z.string().min(1),
  plannedArrivalTime: z.string().datetime().nullish(),
  progressDetails: MissionProgressDetailsSchema.nullish(),
  route: GeneratedRouteSchema.nullish(),
  state: MissionStateSchema,
  updatedAt: z.string().datetime(),
  vin: z.string().min(1)
})
