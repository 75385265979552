import { createApi } from '@reduxjs/toolkit/query/react'
import { MissionSchema } from 'APIzod'
import { client } from 'graphql/client'
import { Mission } from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { getMission } from 'graphql/queries'
import { onUpdateMission } from '../graphql/subscriptions'
import { errorHandler } from './errors'

const getMissionQueryFn = async (id: string) => {
  try {
    const { data } = await client.graphql({ query: getMission, variables: { id } })
    return { data: MissionSchema.parse(data.getMission) }
  } catch (err) {
    throw errorHandler('Unexpected error occurred while fetching mission', err)
  }
}

export const missionApi = createApi({
  reducerPath: 'mission',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMission: builder.query<Mission, string>({
      queryFn: getMissionQueryFn
    }),
    onMissionSubscription: builder.query<Mission, string>({
      queryFn: getMissionQueryFn,
      onCacheEntryAdded: async (id, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) => {
        const updateCache = (data: Mission) => {
          const mission = MissionSchema.parse(data)
          updateCachedData(() => mission)
        }

        await cacheDataLoaded
        if (import.meta.env.DEV) {
          // mockSubscription({
          //   timeOutMs: 10_000,
          //   maxIterations: 10,
          //   mockFn: mockMission,
          //   callback: (data) => {
          //     const updatedMission = MissionSchema.parse(data.onUpdateMission)
          //     updateCache(updatedMission)
          //   }
          // })
          // registerMissionsDevTools((data) => {
          //   updateCache(data.onMissionUpdate)
          // })
          // await cacheEntryRemoved
        } else {
          const connection = client
            .graphql({ query: onUpdateMission, variables: { id } })
            .subscribe({
              next: ({ data }) => {
                if (!data.onUpdateMission) {
                  console.error(
                    '[onMissionSubscription]: Please make sure all the attributes are returned in the mutation'
                  )
                }
                updateCache(data.onUpdateMission)
              },
              error: (error) => console.warn(error)
            })
          cacheEntryRemoved
            .then(() => {
              connection.unsubscribe()
            })
            .catch((error) => console.error(error))
        }
      }
    })
  })
})

export const { useGetMissionQuery, useOnMissionSubscriptionQuery } = missionApi
