import { factory, manyOf, oneOf, primaryKey } from '@mswjs/data'
import { faker } from '@faker-js/faker'
import { fakePosition } from './utils'
import { v4 as uuid } from 'uuid'
import {
  AdsState,
  DrivingState,
  Reason,
  Severity,
  Status,
  Command,
  ExecutionState,
  MissionStateValue,
  MissionEventType
} from 'API'
import { WAYPOINTS } from './constants'

faker.seed(1337)

export const VINS = [
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin(),
  faker.vehicle.vin()
]
export const MISSION_IDS = [
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid()
]
export const HUB_IDS = [
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid(),
  faker.string.uuid()
]

const position = {
  latitude: () => Number(fakePosition().latitude),
  longitude: () => Number(fakePosition().longitude),
  heading: () => Number(fakePosition().heading),
  altitude: () => Number(fakePosition().altitude)
}

const status = {
  type: () => faker.helpers.arrayElement(Object.values(Status)),
  reason: () => faker.helpers.arrayElement(Object.values(Reason)),
  description: () => faker.lorem.sentence()
}

const state = {
  updatedAt: () => faker.date.anytime().toISOString(),
  value: () => faker.helpers.arrayElement(Object.values(MissionStateValue))
}

// Data Model
export const MockDatabase = factory({
  hub: {
    id: primaryKey(() => faker.helpers.arrayElement(HUB_IDS)),
    name: () => faker.company.name(),
    address: () => faker.location.streetAddress(),
    position,
    active: () => faker.datatype.boolean(),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString()
  },
  commandInfo: {
    id: primaryKey(faker.string.uuid),
    command: () => faker.helpers.arrayElement(Object.values(Command)),
    executionState: () => faker.helpers.arrayElement(Object.values(ExecutionState)),
    lastUpdatedAt: () => faker.date.anytime().toISOString(),
    errorMessage: () => faker.lorem.sentence()
  },
  vehicle: {
    vin: primaryKey(faker.vehicle.vin),
    externalId: () => faker.string.uuid(),
    customerId: () => faker.string.uuid(),
    name: () => faker.person.firstName(),
    licensePlate: () => faker.vehicle.vrm(),
    position,
    adsState: () => faker.helpers.arrayElement(Object.values(AdsState)),
    drivingState: () => faker.helpers.arrayElement(Object.values(DrivingState)),
    speedInKmh: () => faker.number.float(),
    weightInKg: () => faker.number.float(),
    createdAt: () => faker.date.anytime().toISOString(),
    updatedAt: () => faker.date.anytime().toISOString(),
    latestVehicleUpdate: () => faker.date.recent().toISOString(),
    status,
    alerts: manyOf('alert'),
    owners: manyOf('owner'),
    commandInfo: oneOf('commandInfo'),
    activeMissionId: () => faker.helpers.arrayElement(MISSION_IDS)
  },
  alert: {
    id: primaryKey(faker.string.uuid),
    severity: () => faker.helpers.arrayElement(Object.values(Severity)),
    category: () => faker.string.sample(),
    description: () => faker.lorem.text(),
    vin: () => faker.string.uuid(),
    sentAt: () => faker.date.anytime().toISOString()
  },
  owner: {
    id: primaryKey(faker.string.uuid),
    fullName: () => `${faker.person.firstName()} ${faker.person.lastName()}`,
    position: () => faker.person.jobTitle(),
    phoneNumber: () => faker.phone.number(),
    email: () => faker.internet.email()
  },
  missionEvent: {
    id: primaryKey(faker.string.uuid),
    type: () => faker.helpers.arrayElement(Object.values(MissionEventType)),
    createdAt: () => faker.date.anytime().toISOString(),
    position
  },
  mission: {
    id: primaryKey(() => faker.helpers.arrayElement(MISSION_IDS)),
    actualArrivalTime: () => faker.date.anytime().toISOString(),
    actualStartTime: () => faker.date.anytime().toISOString(),
    createdAt: () => faker.date.anytime().toISOString(),
    description: () => faker.lorem.paragraph(),
    name: () => faker.person.firstName(),
    plannedArrivalTime: () => faker.date.anytime().toISOString(),
    progressDetails: oneOf('progressDetails'),
    route: oneOf('route'),
    state,
    updatedAt: () => faker.date.anytime().toISOString(),
    vin: () => faker.helpers.arrayElement(VINS),
    events: manyOf('missionEvent')
  },
  progressDetails: {
    id: primaryKey(faker.string.uuid),
    eta: () => faker.date.anytime().toISOString(),
    remainingDistanceInMeters: () => faker.number.int(), // TODO: define a better range
    traveledDistanceInMeters: () => faker.number.int(),
    updatedAt: () => faker.date.anytime().toISOString()
  },
  route: {
    id: primaryKey(() => faker.string.uuid()),
    createdAt: () => faker.date.anytime().toISOString(),
    durationInSeconds: () => faker.number.int({ min: 60, max: 4 * 3600 }),
    externalRouteId: () => faker.string.uuid(),
    landingHub: oneOf('hub'),
    landingHubId: () => faker.helpers.arrayElement(HUB_IDS),
    launchHub: oneOf('hub'),
    launchHubId: () => faker.helpers.arrayElement(HUB_IDS),
    predefinedRouteId: () => faker.string.uuid(),
    totalDistanceInMeters: () => faker.number.int({ min: 1200, max: 500_000 }),
    updatedAt: () => faker.date.anytime().toISOString(),
    // TODO: find a cleaner way to define this.
    // Actually we just need the fixed values from our waypoints mock but still need some type here
    waypoints: () => faker.helpers.arrayElements([]) as any
  }
})

// Hubs
MockDatabase.hub.create({
  id: HUB_IDS[0],
  name: 'Hub I',
  active: true,
  address: faker.location.streetAddress(),
  position: {
    latitude: 48.3418733,
    longitude: 11.6067098
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[1],
  name: 'Hub II',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.3866331,
    longitude: 11.5974676
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[2],
  name: 'Hub Riem Arcaden',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.13243255052443,
    longitude: 11.691210499999999
  }
})

MockDatabase.hub.create({
  id: HUB_IDS[3],
  name: 'Hub Augsburg Airport',
  address: faker.location.streetAddress(),
  active: true,
  position: {
    latitude: 48.42788804304301,
    longitude: 10.952264305199673
  }
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Bumblebee',
  vin: VINS[0],
  drivingState: DrivingState.DRIVING,
  position: {
    latitude: 48.3408733,
    longitude: 11.6067098,
    heading: 0.0
  },
  commandInfo: MockDatabase.commandInfo.create({
    command: undefined,
    executionState: undefined
  }),
  activeMissionId: MISSION_IDS[0],
  status: {
    type: Status.ONLINE
  },
  owners: [MockDatabase.owner.create(), MockDatabase.owner.create()],
  alerts: [
    MockDatabase.alert.create({
      vin: VINS[0],
      description: 'H2H_MRC',
      severity: Severity.ERROR
    })
  ]
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Optimus Prime',
  vin: VINS[1],
  drivingState: DrivingState.DRIVING,
  activeMissionId: MISSION_IDS[1],
  position: {
    latitude: 48,
    longitude: 11,
    heading: 0,
    altitude: 0
  },
  speedInKmh: 0,
  commandInfo: MockDatabase.commandInfo.create({
    command: Command.MRM,
    executionState: ExecutionState.REACHED
  }),
  alerts: [
    MockDatabase.alert.create({
      vin: VINS[1],
      description: 'H2H_MRC',
      severity: Severity.ERROR
    })
  ],
  owners: [MockDatabase.owner.create()]
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Megatron',
  vin: VINS[2],
  drivingState: DrivingState.DRIVING,
  activeMissionId: MISSION_IDS[2],
  position: {
    latitude: 48.1966921,
    longitude: 11.6994121,
    heading: 0.0
  },
  alerts: [
    MockDatabase.alert.create({
      vin: VINS[2]
    })
  ],
  // providing a commandInfo object with undefined values results in MSW/data picking MRM/REQUESTED
  owners: [MockDatabase.owner.create(), MockDatabase.owner.create()]
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Lisbon Express',
  vin: VINS[3],
  activeMissionId: undefined,
  position: {
    latitude: 38.76469311858257,
    longitude: -9.046360110212152,
    heading: 0.0
  },
  alerts: [
    MockDatabase.alert.create({
      vin: VINS[3]
    })
  ],
  owners: []
})

MockDatabase.vehicle.create({
  vin: VINS[4],
  drivingState: DrivingState.DRIVING,
  activeMissionId: MISSION_IDS[3],
  alerts: [
    MockDatabase.alert.create({
      vin: VINS[4],
      severity: Severity.WARNING
    })
  ]
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Starscream',
  vin: VINS[5],
  activeMissionId: MISSION_IDS[5],
  position: {
    latitude: 48.423773765612154,
    longitude: 10.9199119181742,
    heading: 0.0
  },
  alerts: [],
  owners: []
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Blackout',
  vin: VINS[6],
  activeMissionId: MISSION_IDS[6],
  position: {
    latitude: 48.41959268770702,
    longitude: 10.919932745248357,
    heading: 0.0
  },
  alerts: [],
  owners: []
})

MockDatabase.vehicle.create({
  externalId: uuid(),
  name: 'Barricade',
  vin: VINS[7],
  activeMissionId: MISSION_IDS[7],
  position: {
    latitude: 48.42064928352072,
    longitude: 10.928484762212223,
    heading: 0.0
  },
  alerts: [],
  owners: []
})

// assigned
MockDatabase.mission.create({
  id: MISSION_IDS[3],
  vin: VINS[4],
  state: {
    value: MissionStateValue.ASSIGNED
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[0]?.id,
    landingHubId: MockDatabase.hub.getAll()[1]?.id,
    launchHub: MockDatabase.hub.getAll()[0],
    landingHub: MockDatabase.hub.getAll()[1],
    durationInSeconds: 6 * 3600, // 6 hours
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 500_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:07:00Z').toISOString(),
    traveledDistanceInMeters: 480_000,
    remainingDistanceInMeters: 20_000
  })
})

// in proggress - on time
MockDatabase.mission.create({
  id: MISSION_IDS[0],
  vin: VINS[0],
  state: {
    value: MissionStateValue.ACTIVE
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[0]?.id,
    landingHubId: MockDatabase.hub.getAll()[1]?.id,
    launchHub: MockDatabase.hub.getAll()[0],
    landingHub: MockDatabase.hub.getAll()[1],
    durationInSeconds: 6 * 3600, // 6 hours
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 500_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:30:00Z').toISOString(),
    traveledDistanceInMeters: 10_000,
    remainingDistanceInMeters: 490_000
  }),
  events: [
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T12:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_STARTED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T13:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T14:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_COMPLETED
    })
  ]
})

// in progress - early
MockDatabase.mission.create({
  id: MISSION_IDS[1],
  vin: VINS[1],
  state: {
    value: MissionStateValue.ACTIVE
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[0]?.id,
    landingHubId: MockDatabase.hub.getAll()[1]?.id,
    launchHub: MockDatabase.hub.getAll()[0],
    landingHub: MockDatabase.hub.getAll()[1],
    durationInSeconds: 6 * 3600, // 6 hours
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 500_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:07:00Z').toISOString(),
    traveledDistanceInMeters: 480_000,
    remainingDistanceInMeters: 20_000
  }),
  events: [
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T12:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_STARTED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T13:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T14:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_PAUSED_BY_MRM
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_RESUMED
    }),
    MockDatabase.missionEvent.create({
      createdAt: new Date('2024-08-22T15:00:00.000Z').toISOString(),
      type: MissionEventType.MISSION_COMPLETED
    })
  ]
})

// in progress - delayed
MockDatabase.mission.create({
  id: MISSION_IDS[2],
  vin: VINS[2],
  state: {
    value: MissionStateValue.PAUSED
  },
  plannedArrivalTime: new Date('2024-08-20T13:30:00Z').toISOString(),
  actualStartTime: new Date('2024-08-20T09:00:00Z').toISOString(),
  actualArrivalTime: undefined,
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[0]?.id,
    landingHubId: MockDatabase.hub.getAll()[1]?.id,
    launchHub: MockDatabase.hub.getAll()[0],
    landingHub: MockDatabase.hub.getAll()[1],
    durationInSeconds: 6 * 3600, // 6 hours
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 500_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:55:00Z').toISOString(),
    traveledDistanceInMeters: 200_000,
    remainingDistanceInMeters: 300_000
  })
})

// complete - on time
MockDatabase.mission.create({
  id: MISSION_IDS[5],
  vin: VINS[5],
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T13:48:00Z').toISOString(),
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[2]?.id,
    landingHubId: MockDatabase.hub.getAll()[3]?.id,
    launchHub: MockDatabase.hub.getAll()[2],
    landingHub: MockDatabase.hub.getAll()[3],
    durationInSeconds: 50 * 60,
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 82_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:50:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// complete - delayed
MockDatabase.mission.create({
  id: MISSION_IDS[6],
  vin: VINS[6],
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T14:15:00Z').toISOString(),
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[2]?.id,
    landingHubId: MockDatabase.hub.getAll()[3]?.id,
    launchHub: MockDatabase.hub.getAll()[2],
    landingHub: MockDatabase.hub.getAll()[3],
    durationInSeconds: 50 * 60,
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 82_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T14:15:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// complete - early
MockDatabase.mission.create({
  id: MISSION_IDS[7],
  vin: VINS[7],
  state: {
    value: MissionStateValue.COMPLETED
  },
  actualStartTime: new Date('2024-08-20T13:00:00Z').toISOString(),
  plannedArrivalTime: new Date('2024-08-20T13:50:00Z').toISOString(),
  actualArrivalTime: new Date('2024-08-20T13:40:00Z').toISOString(),
  route: MockDatabase.route.create({
    launchHubId: MockDatabase.hub.getAll()[2]?.id,
    landingHubId: MockDatabase.hub.getAll()[3]?.id,
    launchHub: MockDatabase.hub.getAll()[2],
    landingHub: MockDatabase.hub.getAll()[3],
    durationInSeconds: 50 * 60,
    waypoints: WAYPOINTS.map(({ latitude, longitude }) => ({ latitude, longitude })),
    totalDistanceInMeters: 82_000
  }),
  progressDetails: MockDatabase.progressDetails.create({
    eta: new Date('2024-08-20T13:38:00Z').toISOString(),
    traveledDistanceInMeters: 82_000,
    remainingDistanceInMeters: 0
  })
})

// Workaround to prevent msw/data to create activeMissionIds although setting them explicitly as undefined
// const [_, ...vehiclesWithoutMission] = VINS

MockDatabase.vehicle.updateMany({
  where: {
    vin: {
      equals: VINS[3]
    }
  },
  data: {
    activeMissionId: undefined
  }
})
