/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getHub = /* GraphQL */ `query GetHub($id: ID!) {
  getHub(id: $id) {
    id
    name
    position {
      latitude
      longitude
      altitude
      heading
    }
    active
    address
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.GetHubQueryVariables, APITypes.GetHubQuery>;
export const getMission = /* GraphQL */ `query GetMission($id: ID!) {
  getMission(id: $id) {
    id
    name
    description
    createdAt
    updatedAt
    route {
      predefinedRouteId
      externalRouteId
      launchHubId
      launchHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      landingHubId
      landingHub {
        id
        name
        position {
          latitude
          longitude
          altitude
          heading
        }
        active
        address
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      totalDistanceInMeters
      durationInSeconds
      waypoints {
        latitude
        longitude
        altitude
        heading
      }
    }
    vin
    actualStartTime
    actualArrivalTime
    plannedArrivalTime
    state {
      value
      updatedAt
    }
    progressDetails {
      updatedAt
      eta
      traveledDistanceInMeters
      remainingDistanceInMeters
    }
    events {
      type
      createdAt
      position {
        latitude
        longitude
        altitude
        heading
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.GetMissionQueryVariables,
  APITypes.GetMissionQuery
>;
export const getVehicleByVIN = /* GraphQL */ `query GetVehicleByVIN($vin: String!) {
  getVehicleByVIN(vin: $vin) {
    vin
    externalId
    name
    licensePlate
    customerId
    speedInKmh
    weightInKg
    drivingState
    owners {
      id
      fullName
      position
      phoneNumber
      email
    }
    adsState
    status {
      type
      reason
      description
    }
    position {
      latitude
      longitude
      altitude
      heading
    }
    commandInfo {
      command
      executionState
      lastUpdatedAt
      errorMessage
    }
    alerts {
      id
      severity
      description
      category
      sentAt
    }
    latestVehicleUpdate
    createdAt
    updatedAt
    activeMissionId
  }
}
` as GeneratedQuery<
  APITypes.GetVehicleByVINQueryVariables,
  APITypes.GetVehicleByVINQuery
>;
export const listHubs = /* GraphQL */ `query ListHubs($limit: Int, $nextToken: String) {
  listHubs(limit: $limit, nextToken: $nextToken) {
    id
    name
    position {
      latitude
      longitude
      altitude
      heading
    }
    active
    address
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<APITypes.ListHubsQueryVariables, APITypes.ListHubsQuery>;
export const listVehicles = /* GraphQL */ `query ListVehicles {
  listVehicles {
    vin
    externalId
    name
    licensePlate
    customerId
    speedInKmh
    weightInKg
    drivingState
    owners {
      id
      fullName
      position
      phoneNumber
      email
    }
    adsState
    status {
      type
      reason
      description
    }
    position {
      latitude
      longitude
      altitude
      heading
    }
    commandInfo {
      command
      executionState
      lastUpdatedAt
      errorMessage
    }
    alerts {
      id
      severity
      description
      category
      sentAt
    }
    latestVehicleUpdate
    createdAt
    updatedAt
    activeMissionId
  }
}
` as GeneratedQuery<
  APITypes.ListVehiclesQueryVariables,
  APITypes.ListVehiclesQuery
>;
export const listRoutes = /* GraphQL */ `query ListRoutes {
  listRoutes {
    id
    launchHubId
    launchHub {
      id
      name
      position {
        latitude
        longitude
        altitude
        heading
      }
      active
      address
      createdAt
      updatedAt
    }
    landingHubId
    landingHub {
      id
      name
      position {
        latitude
        longitude
        altitude
        heading
      }
      active
      address
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    distance
    averageDuration
    waypoints {
      latitude
      longitude
      altitude
      heading
    }
  }
}
` as GeneratedQuery<
  APITypes.ListRoutesQueryVariables,
  APITypes.ListRoutesQuery
>;
