import { Navigate, createBrowserRouter } from 'react-router-dom'
import ErrorBoundary from 'components/ErrorBoundary'
import AppLayout from 'layout/AppLayout'
import { ROUTES } from './types'
import MainPage from 'pages/MainPage'
import ErrorFallback from 'components/ErrorFallback'

export const router = createBrowserRouter([
  {
    path: ROUTES.DEFAULT,
    errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
    element: <AppLayout />,
    children: [
      {
        index: true,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        element: <MainPage />
      },
      {
        path: ROUTES.VEHICLE,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        lazy: async () => {
          const { default: NoVehicleSelected } = await import('pages/vehicle/NoVehicleSelected')
          return { Component: NoVehicleSelected }
        }
      },
      {
        path: ROUTES.VEHICLE_BY_VIN,
        errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
        lazy: async () => {
          const { default: VehiclePage } = await import('pages/vehicle/VehiclePage')
          return { Component: VehiclePage }
        },
        children: [
          {
            path: ROUTES.VEHICLE_MISSION,
            errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
            lazy: async () => {
              const { default: VehicleMission } = await import(
                'pages/vehicle/mission/VehicleMission'
              )
              return { Component: VehicleMission }
            }
          },
          {
            path: ROUTES.VEHICLE_INFO,
            errorElement: <ErrorBoundary FallbackComponent={ErrorFallback} />,
            lazy: async () => {
              const { default: VehicleInfo } = await import('pages/vehicle/info/VehicleInfo')
              return { Component: VehicleInfo }
            }
          },
          {
            path: '*',
            element: <Navigate to={ROUTES.VEHICLE_MISSION} replace />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.DEFAULT} replace />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.DEFAULT} replace />
  }
])
