import { Vehicle } from 'API'
import { GenericRecord } from 'services/utils'

export const getVehicleByVin = (
  vehicleData: GenericRecord<Vehicle> | undefined,
  vin: Vehicle['vin']
) => {
  return vehicleData?.[vin]
}

export const getVehicleIdentifier = (vehicle: Vehicle) => {
  return vehicle.name || vehicle.licensePlate || vehicle.vin.slice(-7)
}
