import { createApi } from '@reduxjs/toolkit/query/react'
import { client } from 'graphql/client'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { VehicleCommandInput, CommandResponse } from '../API'
import { triggerVehicleCommand } from '../graphql/mutations'
import { CommandResponseSchema } from '../APIzod'
import { errorHandler } from './errors'

// TODO: check if we need to redefine this once we have defined a proper error handling in place
// export const isInvocationError = (
//   commandResponseOrError: CommandResponseOrInvocationError
// ): commandResponseOrError is InvocationError => {
//   return (commandResponseOrError as InvocationError).errorType !== undefined
// }

export const commandApi = createApi({
  reducerPath: 'command',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    sendCommand: builder.mutation<CommandResponse, VehicleCommandInput>({
      queryFn: async (variables: VehicleCommandInput) => {
        try {
          const { data } = await client.graphql({
            query: triggerVehicleCommand,
            variables: {
              input: variables
            }
          })
          return { data: CommandResponseSchema.parse(data.triggerVehicleCommand) }
        } catch (err) {
          throw errorHandler('Unexpected error occurred while triggering the command', err)
        }
      }
    })
  })
})

export const { useSendCommandMutation } = commandApi
