export const WAYPOINTS = [
  {
    latitude: 48.3418733,
    longitude: 11.6067098,
    heading: 339.0087724008076,
    altitude: 0.0
  },
  {
    latitude: 48.3419322,
    longitude: 11.6066758,
    heading: 339.04128069805654,
    altitude: 0.0
  },
  {
    latitude: 48.3419912,
    longitude: 11.6066418,
    heading: 338.9524773480178,
    altitude: 0.0
  },
  {
    latitude: 48.3420501,
    longitude: 11.6066077,
    heading: 339.0088388700089,
    altitude: 0.0
  },
  {
    latitude: 48.342109,
    longitude: 11.6065737,
    heading: 339.00886100963254,
    altitude: 0.0
  },
  {
    latitude: 48.3421679,
    longitude: 11.6065397,
    heading: 339.04136919333916,
    altitude: 0.0
  },
  {
    latitude: 48.3422269,
    longitude: 11.6065057,
    heading: 338.9525661504,
    altitude: 0.0
  },
  {
    latitude: 48.3422858,
    longitude: 11.6064716,
    heading: 339.00892747619014,
    altitude: 0.0
  },
  {
    latitude: 48.3423447,
    longitude: 11.6064376,
    heading: 339.00894961771365,
    altitude: 0.0
  },
  {
    latitude: 48.3424036,
    longitude: 11.6064036,
    heading: 339.04145769084073,
    altitude: 0.0
  },
  {
    latitude: 48.3424626,
    longitude: 11.6063696,
    heading: 339.00899393988516,
    altitude: 0.0
  },
  {
    latitude: 48.3425215,
    longitude: 11.6063356,
    heading: 338.9526771466976,
    altitude: 0.0
  },
  {
    latitude: 48.3425804,
    longitude: 11.6063015,
    heading: 339.0415240710366,
    altitude: 0.0
  },
  {
    latitude: 48.3426394,
    longitude: 11.6062675,
    heading: 339.00906040885707,
    altitude: 0.0
  },
  {
    latitude: 48.3426983,
    longitude: 11.6062335,
    heading: 339.00908254964526,
    altitude: 0.0
  },
  {
    latitude: 48.3427572,
    longitude: 11.6061995,
    heading: 338.9527659470796,
    altitude: 0.0
  },
  {
    latitude: 48.3428161,
    longitude: 11.6061654,
    heading: 339.0416125703199,
    altitude: 0.0
  },
  {
    latitude: 48.3428751,
    longitude: 11.6061314,
    heading: 339.00914901714674,
    altitude: 0.0
  },
  {
    latitude: 48.342934,
    longitude: 11.6060974,
    heading: 339.0091711580423,
    altitude: 0.0
  },
  {
    latitude: 48.3429929,
    longitude: 11.6060634,
    heading: 338.9528547489296,
    altitude: 0.0
  },
  {
    latitude: 48.3430518,
    longitude: 11.6060293,
    heading: 339.0417010670733,
    altitude: 0.0
  },
  {
    latitude: 48.3431108,
    longitude: 11.6059953,
    heading: 339.0092376255889,
    altitude: 0.0
  },
  {
    latitude: 48.3431697,
    longitude: 11.6059613,
    heading: 338.3645224824236,
    altitude: 0.0
  },
  {
    latitude: 48.3438373,
    longitude: 11.6055629,
    heading: 338.36279257134316,
    altitude: 0.0
  },
  {
    latitude: 48.344505,
    longitude: 11.6051644,
    heading: 338.36503692572893,
    altitude: 0.0
  },
  {
    latitude: 48.3451726,
    longitude: 11.604766,
    heading: 338.36529413318675,
    altitude: 0.0
  },
  {
    latitude: 48.3458402,
    longitude: 11.6043676,
    heading: 338.36555134439834,
    altitude: 0.0
  },
  {
    latitude: 48.3465078,
    longitude: 11.6039692,
    heading: 338.3638215236306,
    altitude: 0.0
  },
  {
    latitude: 48.3471755,
    longitude: 11.6035707,
    heading: 338.36606581645515,
    altitude: 0.0
  },
  {
    latitude: 48.3478431,
    longitude: 11.6031723,
    heading: 338.36632303824075,
    altitude: 0.0
  },
  {
    latitude: 48.3485107,
    longitude: 11.6027739,
    heading: 338.36658026387363,
    altitude: 0.0
  },
  {
    latitude: 48.3491783,
    longitude: 11.6023755,
    heading: 338.3648505330812,
    altitude: 0.0
  },
  {
    latitude: 48.349846,
    longitude: 11.601977,
    heading: 338.36709476404184,
    altitude: 0.0
  },
  {
    latitude: 48.3505136,
    longitude: 11.6015786,
    heading: 338.36735200077953,
    altitude: 0.0
  },
  {
    latitude: 48.3511812,
    longitude: 11.6011802,
    heading: 338.36562233750914,
    altitude: 0.0
  },
  {
    latitude: 48.3518489,
    longitude: 11.6007817,
    heading: 338.3678665226431,
    altitude: 0.0
  },
  {
    latitude: 48.3525165,
    longitude: 11.6003833,
    heading: 338.3681237694869,
    altitude: 0.0
  },
  {
    latitude: 48.3531841,
    longitude: 11.5999849,
    heading: 338.3683810201146,
    altitude: 0.0
  },
  {
    latitude: 48.3538517,
    longitude: 11.5995865,
    heading: 338.3666514473317,
    altitude: 0.0
  },
  {
    latitude: 48.3545194,
    longitude: 11.599188,
    heading: 338.36889557088534,
    altitude: 0.0
  },
  {
    latitude: 48.355187,
    longitude: 11.5987896,
    heading: 338.3691528320564,
    altitude: 0.0
  },
  {
    latitude: 48.3558546,
    longitude: 11.5983912,
    heading: 338.36941009737035,
    altitude: 0.0
  },
  {
    latitude: 48.3565222,
    longitude: 11.5979928,
    heading: 338.36768061458093,
    altitude: 0.0
  },
  {
    latitude: 48.3571899,
    longitude: 11.5975943,
    heading: 338.3699246762666,
    altitude: 0.0
  },
  {
    latitude: 48.3578575,
    longitude: 11.5971959,
    heading: 339.35276357649525,
    altitude: 0.0
  },
  {
    latitude: 48.357897,
    longitude: 11.5971735,
    heading: 339.2684252715782,
    altitude: 0.0
  },
  {
    latitude: 48.3579365,
    longitude: 11.597151,
    heading: 339.3527928934768,
    altitude: 0.0
  },
  {
    latitude: 48.357976,
    longitude: 11.5971286,
    heading: 339.35280755475975,
    altitude: 0.0
  },
  {
    latitude: 48.3580155,
    longitude: 11.5971062,
    heading: 339.2684693942469,
    altitude: 0.0
  },
  {
    latitude: 48.358055,
    longitude: 11.5970837,
    heading: 339.35283686724534,
    altitude: 0.0
  },
  {
    latitude: 48.3580945,
    longitude: 11.5970613,
    heading: 339.2684988106349,
    altitude: 0.0
  },
  {
    latitude: 48.358134,
    longitude: 11.5970388,
    heading: 339.3528661856872,
    altitude: 0.0
  },
  {
    latitude: 48.3581735,
    longitude: 11.5970164,
    heading: 339.3528808470292,
    altitude: 0.0
  },
  {
    latitude: 48.358213,
    longitude: 11.596994,
    heading: 339.2685429302403,
    altitude: 0.0
  },
  {
    latitude: 48.3582525,
    longitude: 11.5969715,
    heading: 339.3529101596329,
    altitude: 0.0
  },
  {
    latitude: 48.358292,
    longitude: 11.5969491,
    heading: 339.3529248211904,
    altitude: 0.0
  },
  {
    latitude: 48.3583315,
    longitude: 11.5969267,
    heading: 339.2685870558753,
    altitude: 0.0
  },
  {
    latitude: 48.358371,
    longitude: 11.5969042,
    heading: 339.3529541382518,
    altitude: 0.0
  },
  {
    latitude: 48.3584105,
    longitude: 11.5968818,
    heading: 339.3529687937556,
    altitude: 0.0
  },
  {
    latitude: 48.35845,
    longitude: 11.5968594,
    heading: 339.26863118046316,
    altitude: 0.0
  },
  {
    latitude: 48.3584895,
    longitude: 11.5968369,
    heading: 339.35299811070774,
    altitude: 0.0
  },
  {
    latitude: 48.358529,
    longitude: 11.5968145,
    heading: 339.26866059424435,
    altitude: 0.0
  },
  {
    latitude: 48.3585685,
    longitude: 11.596792,
    heading: 339.35302742824734,
    altitude: 0.0
  },
  {
    latitude: 48.358608,
    longitude: 11.5967696,
    heading: 339.3530420866747,
    altitude: 0.0
  },
  {
    latitude: 48.3586475,
    longitude: 11.5967472,
    heading: 339.26870472187835,
    altitude: 0.0
  },
  {
    latitude: 48.358687,
    longitude: 11.5967247,
    heading: 339.3530714052672,
    altitude: 0.0
  },
  {
    latitude: 48.3587265,
    longitude: 11.5967023,
    heading: 341.0911087322843,
    altitude: 0.0
  },
  {
    latitude: 48.3587587,
    longitude: 11.5966857,
    heading: 341.0911198350543,
    altitude: 0.0
  },
  {
    latitude: 48.3587909,
    longitude: 11.5966691,
    heading: 341.19701406322434,
    altitude: 0.0
  },
  {
    latitude: 48.3588231,
    longitude: 11.5966526,
    heading: 341.0364387650998,
    altitude: 0.0
  },
  {
    latitude: 48.3588552,
    longitude: 11.596636,
    heading: 341.0911531069819,
    altitude: 0.0
  },
  {
    latitude: 48.3588874,
    longitude: 11.5966194,
    heading: 341.091164211515,
    altitude: 0.0
  },
  {
    latitude: 48.3589196,
    longitude: 11.5966028,
    heading: 341.19705822986555,
    altitude: 0.0
  },
  {
    latitude: 48.3589518,
    longitude: 11.5965863,
    heading: 341.09118641524043,
    altitude: 0.0
  },
  {
    latitude: 48.358984,
    longitude: 11.5965697,
    heading: 341.0911975215297,
    altitude: 0.0
  },
  {
    latitude: 48.3590162,
    longitude: 11.5965531,
    heading: 341.09120862425135,
    altitude: 0.0
  },
  {
    latitude: 48.3590484,
    longitude: 11.5965365,
    heading: 341.19710242916574,
    altitude: 0.0
  },
  {
    latitude: 48.3590806,
    longitude: 11.59652,
    heading: 341.036527765703,
    altitude: 0.0
  },
  {
    latitude: 48.3591127,
    longitude: 11.5965034,
    heading: 341.09124189818675,
    altitude: 0.0
  },
  {
    latitude: 48.3591449,
    longitude: 11.5964868,
    heading: 341.0912529974142,
    altitude: 0.0
  },
  {
    latitude: 48.3591771,
    longitude: 11.5964702,
    heading: 341.0912641055328,
    altitude: 0.0
  },
  {
    latitude: 48.3592093,
    longitude: 11.5964536,
    heading: 341.1971576458583,
    altitude: 0.0
  },
  {
    latitude: 48.3592415,
    longitude: 11.5964371,
    heading: 341.09128630764957,
    altitude: 0.0
  },
  {
    latitude: 48.3592737,
    longitude: 11.5964205,
    heading: 341.09129740700956,
    altitude: 0.0
  },
  {
    latitude: 48.3593059,
    longitude: 11.5964039,
    heading: 341.03660564720224,
    altitude: 0.0
  },
  {
    latitude: 48.359338,
    longitude: 11.5963873,
    heading: 341.19720180759765,
    altitude: 0.0
  },
  {
    latitude: 48.3593702,
    longitude: 11.5963708,
    heading: 341.0913306828483,
    altitude: 0.0
  },
  {
    latitude: 48.3594024,
    longitude: 11.5963542,
    heading: 341.0913417891242,
    altitude: 0.0
  },
  {
    latitude: 48.3594346,
    longitude: 11.5963376,
    heading: 344.3377267946498,
    altitude: 0.0
  },
  {
    latitude: 48.3594692,
    longitude: 11.596323,
    heading: 344.2789579234611,
    altitude: 0.0
  },
  {
    latitude: 48.3595039,
    longitude: 11.5963083,
    heading: 344.3377470540863,
    altitude: 0.0
  },
  {
    latitude: 48.3595385,
    longitude: 11.5962937,
    heading: 344.33775717561,
    altitude: 0.0
  },
  {
    latitude: 48.3595731,
    longitude: 11.5962791,
    heading: 344.2358074924928,
    altitude: 0.0
  },
  {
    latitude: 48.3596077,
    longitude: 11.5962644,
    heading: 344.3807073883171,
    altitude: 0.0
  },
  {
    latitude: 48.3596424,
    longitude: 11.5962498,
    heading: 344.33778754816063,
    altitude: 0.0
  },
  {
    latitude: 48.359677,
    longitude: 11.5962352,
    heading: 344.235838049131,
    altitude: 0.0
  },
  {
    latitude: 48.3597116,
    longitude: 11.5962205,
    heading: 344.3378077795539,
    altitude: 0.0
  },
  {
    latitude: 48.3597462,
    longitude: 11.5962059,
    heading: 344.3807477786294,
    altitude: 0.0
  },
  {
    latitude: 48.3597809,
    longitude: 11.5961913,
    heading: 344.33782804482297,
    altitude: 0.0
  },
  {
    latitude: 48.3598155,
    longitude: 11.5961767,
    heading: 344.2358787782563,
    altitude: 0.0
  },
  {
    latitude: 48.3598501,
    longitude: 11.596162,
    heading: 344.3807780846578,
    altitude: 0.0
  },
  {
    latitude: 48.3598848,
    longitude: 11.5961474,
    heading: 344.3378584175089,
    altitude: 0.0
  },
  {
    latitude: 48.3599194,
    longitude: 11.5961328,
    heading: 344.2359093325203,
    altitude: 0.0
  },
  {
    latitude: 48.359954,
    longitude: 11.5961181,
    heading: 344.3378786506145,
    altitude: 0.0
  },
  {
    latitude: 48.3599886,
    longitude: 11.5961035,
    heading: 344.3808184735317,
    altitude: 0.0
  },
  {
    latitude: 48.3600233,
    longitude: 11.5960889,
    heading: 344.23593988684274,
    altitude: 0.0
  },
  {
    latitude: 48.3600579,
    longitude: 11.5960742,
    heading: 344.33790902613526,
    altitude: 0.0
  },
  {
    latitude: 48.3600925,
    longitude: 11.5960596,
    heading: 344.3379191420325,
    altitude: 0.0
  },
  {
    latitude: 48.3601271,
    longitude: 11.596045,
    heading: 344.2791509185274,
    altitude: 0.0
  },
  {
    latitude: 48.3601618,
    longitude: 11.5960303,
    heading: 344.33793940308306,
    altitude: 0.0
  },
  {
    latitude: 48.3601964,
    longitude: 11.5960157,
    heading: 347.01683063479425,
    altitude: 0.0
  },
  {
    latitude: 48.360233,
    longitude: 11.596003,
    heading: 346.98247925615937,
    altitude: 0.0
  },
  {
    latitude: 48.3602695,
    longitude: 11.5959903,
    heading: 347.11565365159964,
    altitude: 0.0
  },
  {
    latitude: 48.3603061,
    longitude: 11.5959777,
    heading: 347.0168576421982,
    altitude: 0.0
  },
  {
    latitude: 48.3603427,
    longitude: 11.595965,
    heading: 347.0168666624496,
    altitude: 0.0
  },
  {
    latitude: 48.3603793,
    longitude: 11.5959523,
    heading: 346.98251536659,
    altitude: 0.0
  },
  {
    latitude: 48.3604158,
    longitude: 11.5959396,
    heading: 347.0168846588548,
    altitude: 0.0
  },
  {
    latitude: 48.3604524,
    longitude: 11.5959269,
    heading: 347.0168936711415,
    altitude: 0.0
  },
  {
    latitude: 48.360489,
    longitude: 11.5959142,
    heading: 347.11570731786605,
    altitude: 0.0
  },
  {
    latitude: 48.3605256,
    longitude: 11.5959016,
    heading: 346.98255147759204,
    altitude: 0.0
  },
  {
    latitude: 48.3605621,
    longitude: 11.5958889,
    heading: 347.01692068241715,
    altitude: 0.0
  },
  {
    latitude: 48.3605987,
    longitude: 11.5958762,
    heading: 347.01692969725883,
    altitude: 0.0
  },
  {
    latitude: 48.3606353,
    longitude: 11.5958635,
    heading: 346.9825785555388,
    altitude: 0.0
  },
  {
    latitude: 48.3606718,
    longitude: 11.5958508,
    heading: 347.1157520089144,
    altitude: 0.0
  },
  {
    latitude: 48.3607084,
    longitude: 11.5958382,
    heading: 347.01695670921305,
    altitude: 0.0
  },
  {
    latitude: 48.360745,
    longitude: 11.5958255,
    heading: 347.0169657220265,
    altitude: 0.0
  },
  {
    latitude: 48.3607816,
    longitude: 11.5958128,
    heading: 346.9826146699897,
    altitude: 0.0
  },
  {
    latitude: 48.3608181,
    longitude: 11.5958001,
    heading: 347.0169837190391,
    altitude: 0.0
  },
  {
    latitude: 48.3608547,
    longitude: 11.5957874,
    heading: 347.01699273295986,
    altitude: 0.0
  },
  {
    latitude: 48.3608913,
    longitude: 11.5957747,
    heading: 347.1158056733934,
    altitude: 0.0
  },
  {
    latitude: 48.3609279,
    longitude: 11.5957621,
    heading: 346.9826507840543,
    altitude: 0.0
  },
  {
    latitude: 48.3609644,
    longitude: 11.5957494,
    heading: 347.01701974394507,
    altitude: 0.0
  },
  {
    latitude: 48.361001,
    longitude: 11.5957367,
    heading: 348.95130520543023,
    altitude: 0.0
  },
  {
    latitude: 48.3610255,
    longitude: 11.5957295,
    heading: 349.1010644562077,
    altitude: 0.0
  },
  {
    latitude: 48.36105,
    longitude: 11.5957224,
    heading: 348.99513031253474,
    altitude: 0.0
  },
  {
    latitude: 48.3610746,
    longitude: 11.5957152,
    heading: 349.1010747024969,
    altitude: 0.0
  },
  {
    latitude: 48.3610991,
    longitude: 11.5957081,
    heading: 348.95132596723664,
    altitude: 0.0
  },
  {
    latitude: 48.3611236,
    longitude: 11.5957009,
    heading: 349.1010849382005,
    altitude: 0.0
  },
  {
    latitude: 48.3611481,
    longitude: 11.5956938,
    heading: 348.99515098304164,
    altitude: 0.0
  },
  {
    latitude: 48.3611727,
    longitude: 11.5956866,
    heading: 349.1010951944168,
    altitude: 0.0
  },
  {
    latitude: 48.3611972,
    longitude: 11.5956795,
    heading: 348.9513467205377,
    altitude: 0.0
  },
  {
    latitude: 48.3612217,
    longitude: 11.5956723,
    heading: 349.10110542084965,
    altitude: 0.0
  },
  {
    latitude: 48.3612462,
    longitude: 11.5956652,
    heading: 348.9951716656709,
    altitude: 0.0
  },
  {
    latitude: 48.3612708,
    longitude: 11.595658,
    heading: 349.101115681226,
    altitude: 0.0
  },
  {
    latitude: 48.3612953,
    longitude: 11.5956509,
    heading: 348.95136746687814,
    altitude: 0.0
  },
  {
    latitude: 48.3613198,
    longitude: 11.5956437,
    heading: 349.1011259179652,
    altitude: 0.0
  },
  {
    latitude: 48.3613443,
    longitude: 11.5956366,
    heading: 348.9951923398325,
    altitude: 0.0
  },
  {
    latitude: 48.3613689,
    longitude: 11.5956294,
    heading: 349.1011361676899,
    altitude: 0.0
  },
  {
    latitude: 48.3613934,
    longitude: 11.5956223,
    heading: 348.9513882254265,
    altitude: 0.0
  },
  {
    latitude: 48.3614179,
    longitude: 11.5956151,
    heading: 349.1011464010617,
    altitude: 0.0
  },
  {
    latitude: 48.3614424,
    longitude: 11.595608,
    heading: 348.99521301321874,
    altitude: 0.0
  },
  {
    latitude: 48.361467,
    longitude: 11.5956008,
    heading: 349.1011566573291,
    altitude: 0.0
  },
  {
    latitude: 48.3614915,
    longitude: 11.5955937,
    heading: 348.9514089798124,
    altitude: 0.0
  },
  {
    latitude: 48.361516,
    longitude: 11.5955865,
    heading: 351.69835188762204,
    altitude: 0.0
  },
  {
    latitude: 48.3615415,
    longitude: 11.5955809,
    heading: 351.7303349452915,
    altitude: 0.0
  },
  {
    latitude: 48.3615671,
    longitude: 11.5955753,
    heading: 351.55223730649897,
    altitude: 0.0
  },
  {
    latitude: 48.3615926,
    longitude: 11.5955696,
    heading: 351.73034312343043,
    altitude: 0.0
  },
  {
    latitude: 48.3616182,
    longitude: 11.595564,
    heading: 351.6983683094332,
    altitude: 0.0
  },
  {
    latitude: 48.3616437,
    longitude: 11.5955584,
    heading: 351.7303513096819,
    altitude: 0.0
  },
  {
    latitude: 48.3616693,
    longitude: 11.5955528,
    heading: 351.69837652137005,
    altitude: 0.0
  },
  {
    latitude: 48.3616948,
    longitude: 11.5955472,
    heading: 351.55225817572205,
    altitude: 0.0
  },
  {
    latitude: 48.3617203,
    longitude: 11.5955415,
    heading: 351.7303635724545,
    altitude: 0.0
  },
  {
    latitude: 48.3617459,
    longitude: 11.5955359,
    heading: 351.698388832274,
    altitude: 0.0
  },
  {
    latitude: 48.3617714,
    longitude: 11.5955303,
    heading: 351.73037175163057,
    altitude: 0.0
  },
  {
    latitude: 48.361797,
    longitude: 11.5955247,
    heading: 351.69839704422805,
    altitude: 0.0
  },
  {
    latitude: 48.3618225,
    longitude: 11.5955191,
    heading: 351.73037993052395,
    altitude: 0.0
  },
  {
    latitude: 48.3618481,
    longitude: 11.5955135,
    heading: 351.5522832362953,
    altitude: 0.0
  },
  {
    latitude: 48.3618736,
    longitude: 11.5955078,
    heading: 351.6984093528331,
    altitude: 0.0
  },
  {
    latitude: 48.3618991,
    longitude: 11.5955022,
    heading: 351.7303921989774,
    altitude: 0.0
  },
  {
    latitude: 48.3619247,
    longitude: 11.5954966,
    heading: 351.6984175627622,
    altitude: 0.0
  },
  {
    latitude: 48.3619502,
    longitude: 11.595491,
    heading: 351.7304003799143,
    altitude: 0.0
  },
  {
    latitude: 48.3619758,
    longitude: 11.5954854,
    heading: 351.5523041056144,
    altitude: 0.0
  },
  {
    latitude: 48.3620013,
    longitude: 11.5954797,
    heading: 351.73040855941053,
    altitude: 0.0
  },
  {
    latitude: 48.3620269,
    longitude: 11.5954741,
    heading: 351.698433987746,
    altitude: 0.0
  },
  {
    latitude: 48.3620524,
    longitude: 11.5954685,
    heading: 353.4851986056047,
    altitude: 0.0
  },
  {
    latitude: 48.3621036,
    longitude: 11.5954597,
    heading: 353.48520510083796,
    altitude: 0.0
  },
  {
    latitude: 48.3621548,
    longitude: 11.5954509,
    heading: 353.55861753441957,
    altitude: 0.0
  },
  {
    latitude: 48.362206,
    longitude: 11.5954422,
    heading: 353.4852180823113,
    altitude: 0.0
  },
  {
    latitude: 48.3622572,
    longitude: 11.5954334,
    heading: 353.4852245759921,
    altitude: 0.0
  },
  {
    latitude: 48.3623084,
    longitude: 11.5954246,
    heading: 353.49782157656745,
    altitude: 0.0
  },
  {
    latitude: 48.3623597,
    longitude: 11.5954158,
    heading: 353.55864322660375,
    altitude: 0.0
  },
  {
    latitude: 48.3624109,
    longitude: 11.5954071,
    heading: 353.48524406464014,
    altitude: 0.0
  },
  {
    latitude: 48.3624621,
    longitude: 11.5953983,
    heading: 353.4852505571751,
    altitude: 0.0
  },
  {
    latitude: 48.3625133,
    longitude: 11.5953895,
    heading: 353.48525704891296,
    altitude: 0.0
  },
  {
    latitude: 48.3625645,
    longitude: 11.5953807,
    heading: 353.5586689075458,
    altitude: 0.0
  },
  {
    latitude: 48.3626157,
    longitude: 11.595372,
    heading: 353.48527003357185,
    altitude: 0.0
  },
  {
    latitude: 48.3626669,
    longitude: 11.5953632,
    heading: 353.48527652536217,
    altitude: 0.0
  },
  {
    latitude: 48.3627181,
    longitude: 11.5953544,
    heading: 353.48528301712133,
    altitude: 0.0
  },
  {
    latitude: 48.3627693,
    longitude: 11.5953456,
    heading: 353.48528951008944,
    altitude: 0.0
  },
  {
    latitude: 48.3628205,
    longitude: 11.5953368,
    heading: 353.5587010088133,
    altitude: 0.0
  },
  {
    latitude: 48.3628717,
    longitude: 11.5953281,
    heading: 353.4978928664369,
    altitude: 0.0
  },
  {
    latitude: 48.362923,
    longitude: 11.5953193,
    heading: 353.48530900006455,
    altitude: 0.0
  },
  {
    latitude: 48.3629742,
    longitude: 11.5953105,
    heading: 353.4853154914856,
    altitude: 0.0
  },
  {
    latitude: 48.3630254,
    longitude: 11.5953017,
    heading: 353.5587267016681,
    altitude: 0.0
  },
  {
    latitude: 48.3630766,
    longitude: 11.595293,
    heading: 353.48532847547443,
    altitude: 0.0
  },
  {
    latitude: 48.3631278,
    longitude: 11.5952842,
    heading: 353.48533496847983,
    altitude: 0.0
  },
  {
    latitude: 48.363179,
    longitude: 11.5952754,
    heading: 356.57562818246106,
    altitude: 0.0
  },
  {
    latitude: 48.3632112,
    longitude: 11.5952725,
    heading: 356.45784428129224,
    altitude: 0.0
  },
  {
    latitude: 48.3632434,
    longitude: 11.5952695,
    heading: 356.57563250175326,
    altitude: 0.0
  },
  {
    latitude: 48.3632756,
    longitude: 11.5952666,
    heading: 356.56499235129155,
    altitude: 0.0
  },
  {
    latitude: 48.3633077,
    longitude: 11.5952637,
    heading: 356.5756368150177,
    altitude: 0.0
  },
  {
    latitude: 48.3633399,
    longitude: 11.5952608,
    heading: 356.4578532075481,
    altitude: 0.0
  },
  {
    latitude: 48.3633721,
    longitude: 11.5952578,
    heading: 356.57564113364367,
    altitude: 0.0
  },
  {
    latitude: 48.3634043,
    longitude: 11.5952549,
    heading: 356.5756432914224,
    altitude: 0.0
  },
  {
    latitude: 48.3634365,
    longitude: 11.595252,
    heading: 356.57564545193657,
    altitude: 0.0
  },
  {
    latitude: 48.3634687,
    longitude: 11.5952491,
    heading: 356.4578621425035,
    altitude: 0.0
  },
  {
    latitude: 48.3635009,
    longitude: 11.5952461,
    heading: 356.57564977293333,
    altitude: 0.0
  },
  {
    latitude: 48.3635331,
    longitude: 11.5952432,
    heading: 356.56500967522203,
    altitude: 0.0
  },
  {
    latitude: 48.3635652,
    longitude: 11.5952403,
    heading: 356.45786883731876,
    altitude: 0.0
  },
  {
    latitude: 48.3635974,
    longitude: 11.5952373,
    heading: 356.575656244524,
    altitude: 0.0
  },
  {
    latitude: 48.3636296,
    longitude: 11.5952344,
    heading: 356.57565840231035,
    altitude: 0.0
  },
  {
    latitude: 48.3636618,
    longitude: 11.5952315,
    heading: 356.5756605631696,
    altitude: 0.0
  },
  {
    latitude: 48.363694,
    longitude: 11.5952286,
    heading: 356.4578777690368,
    altitude: 0.0
  },
  {
    latitude: 48.3637262,
    longitude: 11.5952256,
    heading: 356.57566488504267,
    altitude: 0.0
  },
  {
    latitude: 48.3637584,
    longitude: 11.5952227,
    heading: 356.5650248328775,
    altitude: 0.0
  },
  {
    latitude: 48.3637905,
    longitude: 11.5952198,
    heading: 356.5756691957915,
    altitude: 0.0
  },
  {
    latitude: 48.3638227,
    longitude: 11.5952169,
    heading: 356.45788669570925,
    altitude: 0.0
  },
  {
    latitude: 48.3638549,
    longitude: 11.5952139,
    heading: 356.57567351666086,
    altitude: 0.0
  },
  {
    latitude: 48.3638871,
    longitude: 11.595211,
    heading: 359.0578290559914,
    altitude: 0.0
  },
  {
    latitude: 48.3639275,
    longitude: 11.59521,
    heading: 359.0554923356129,
    altitude: 0.0
  },
  {
    latitude: 48.3639678,
    longitude: 11.595209,
    heading: 358.96363322078133,
    altitude: 0.0
  },
  {
    latitude: 48.3640082,
    longitude: 11.5952079,
    heading: 359.0554938321206,
    altitude: 0.0
  },
  {
    latitude: 48.3640485,
    longitude: 11.5952069,
    heading: 359.057832042135,
    altitude: 0.0
  },
  {
    latitude: 48.3640889,
    longitude: 11.5952059,
    heading: 359.05549532825756,
    altitude: 0.0
  },
  {
    latitude: 48.3641292,
    longitude: 11.5952049,
    heading: 359.05783353479427,
    altitude: 0.0
  },
  {
    latitude: 48.3641696,
    longitude: 11.5952039,
    heading: 358.96363732580613,
    altitude: 0.0
  },
  {
    latitude: 48.36421,
    longitude: 11.5952028,
    heading: 359.05549757357994,
    altitude: 0.0
  },
  {
    latitude: 48.3642503,
    longitude: 11.5952018,
    heading: 359.0578357757645,
    altitude: 0.0
  },
  {
    latitude: 48.3642907,
    longitude: 11.5952008,
    heading: 359.0554990667926,
    altitude: 0.0
  },
  {
    latitude: 48.364331,
    longitude: 11.5951998,
    heading: 359.0578372686509,
    altitude: 0.0
  },
  {
    latitude: 48.3643714,
    longitude: 11.5951988,
    heading: 359.0555005642889,
    altitude: 0.0
  },
  {
    latitude: 48.3644117,
    longitude: 11.5951978,
    heading: 358.9636422524892,
    altitude: 0.0
  },
  {
    latitude: 48.3644521,
    longitude: 11.5951967,
    heading: 359.0578395066321,
    altitude: 0.0
  },
  {
    latitude: 48.3644925,
    longitude: 11.5951957,
    heading: 359.0555028113464,
    altitude: 0.0
  },
  {
    latitude: 48.3645328,
    longitude: 11.5951947,
    heading: 359.05784099930077,
    altitude: 0.0
  },
  {
    latitude: 48.3645732,
    longitude: 11.5951937,
    heading: 359.0555043071965,
    altitude: 0.0
  },
  {
    latitude: 48.3646135,
    longitude: 11.5951927,
    heading: 358.963646356126,
    altitude: 0.0
  },
  {
    latitude: 48.3646539,
    longitude: 11.5951916,
    heading: 359.0555058034957,
    altitude: 0.0
  },
  {
    latitude: 48.3646942,
    longitude: 11.5951906,
    heading: 359.0578439827748,
    altitude: 0.0
  },
  {
    latitude: 48.3647346,
    longitude: 11.5951896,
    heading: 1.9073905237823965,
    altitude: 0.0
  },
  {
    latitude: 48.3647745,
    longitude: 11.5951916,
    heading: 2.0077104257166525,
    altitude: 0.0
  },
  {
    latitude: 48.3648143,
    longitude: 11.5951937,
    heading: 1.9073875402158365,
    altitude: 0.0
  },
  {
    latitude: 48.3648542,
    longitude: 11.5951957,
    heading: 2.0077072865898913,
    altitude: 0.0
  },
  {
    latitude: 48.364894,
    longitude: 11.5951978,
    heading: 1.907384557553371,
    altitude: 0.0
  },
  {
    latitude: 48.3649339,
    longitude: 11.5951998,
    heading: 2.0077041486576945,
    altitude: 0.0
  },
  {
    latitude: 48.3649737,
    longitude: 11.5952019,
    heading: 1.9073815750391532,
    altitude: 0.0
  },
  {
    latitude: 48.3650136,
    longitude: 11.5952039,
    heading: 1.912168944846428,
    altitude: 0.0
  },
  {
    latitude: 48.3650534,
    longitude: 11.5952059,
    heading: 2.0026717110611685,
    altitude: 0.0
  },
  {
    latitude: 48.3650933,
    longitude: 11.595208,
    heading: 1.91216595484957,
    altitude: 0.0
  },
  {
    latitude: 48.3651331,
    longitude: 11.59521,
    heading: 2.002668581124908,
    altitude: 0.0
  },
  {
    latitude: 48.365173,
    longitude: 11.5952121,
    heading: 1.9121629646963356,
    altitude: 0.0
  },
  {
    latitude: 48.3652128,
    longitude: 11.5952141,
    heading: 2.0026654479945023,
    altitude: 0.0
  },
  {
    latitude: 48.3652527,
    longitude: 11.5952162,
    heading: 1.9121599748447125,
    altitude: 0.0
  },
  {
    latitude: 48.3652925,
    longitude: 11.5952182,
    heading: 1.9073696450972193,
    altitude: 0.0
  },
  {
    latitude: 48.3653324,
    longitude: 11.5952202,
    heading: 2.007688452785601,
    altitude: 0.0
  },
  {
    latitude: 48.3653722,
    longitude: 11.5952223,
    heading: 1.907366663020298,
    altitude: 0.0
  },
  {
    latitude: 48.3654121,
    longitude: 11.5952243,
    heading: 2.0076853125933667,
    altitude: 0.0
  },
  {
    latitude: 48.3654519,
    longitude: 11.5952264,
    heading: 1.9073636795723132,
    altitude: 0.0
  },
  {
    latitude: 48.3654918,
    longitude: 11.5952284,
    heading: 2.0076821734353985,
    altitude: 0.0
  },
  {
    latitude: 48.3655316,
    longitude: 11.5952305,
    heading: 1.9073606971841173,
    altitude: 0.0
  },
  {
    latitude: 48.3655715,
    longitude: 11.5952325,
    heading: 3.887120798302078,
    altitude: 0.0
  },
  {
    latitude: 48.3656859,
    longitude: 11.5952442,
    heading: 3.916818989792489,
    altitude: 0.0
  },
  {
    latitude: 48.3658004,
    longitude: 11.595256,
    heading: 3.88710338208341,
    altitude: 0.0
  },
  {
    latitude: 48.3659148,
    longitude: 11.5952677,
    heading: 3.9168014406166662,
    altitude: 0.0
  },
  {
    latitude: 48.3660293,
    longitude: 11.5952795,
    heading: 3.8870859650599527,
    altitude: 0.0
  },
  {
    latitude: 48.3661437,
    longitude: 11.5952912,
    heading: 3.916783892137005,
    altitude: 0.0
  },
  {
    latitude: 48.3662582,
    longitude: 11.595303,
    heading: 3.8870685483929037,
    altitude: 0.0
  },
  {
    latitude: 48.3663726,
    longitude: 11.5953147,
    heading: 3.9201794276494297,
    altitude: 0.0
  },
  {
    latitude: 48.366487,
    longitude: 11.5953265,
    heading: 3.883666720534734,
    altitude: 0.0
  },
  {
    latitude: 48.3666015,
    longitude: 11.5953382,
    heading: 3.920161863103658,
    altitude: 0.0
  },
  {
    latitude: 48.3667159,
    longitude: 11.59535,
    heading: 3.883649318814207,
    altitude: 0.0
  },
  {
    latitude: 48.3668304,
    longitude: 11.5953617,
    heading: 3.920144300183381,
    altitude: 0.0
  },
  {
    latitude: 48.3669448,
    longitude: 11.5953735,
    heading: 3.883631917138757,
    altitude: 0.0
  },
  {
    latitude: 48.3670593,
    longitude: 11.5953852,
    heading: 3.92012673444151,
    altitude: 0.0
  },
  {
    latitude: 48.3671737,
    longitude: 11.595397,
    heading: 3.8869988888158105,
    altitude: 0.0
  },
  {
    latitude: 48.3672881,
    longitude: 11.5954087,
    heading: 3.916696154941178,
    altitude: 0.0
  },
  {
    latitude: 48.3674026,
    longitude: 11.5954205,
    heading: 3.886981472374657,
    altitude: 0.0
  },
  {
    latitude: 48.367517,
    longitude: 11.5954322,
    heading: 3.9166786057417653,
    altitude: 0.0
  },
  {
    latitude: 48.3676315,
    longitude: 11.595444,
    heading: 3.8869640554399894,
    altitude: 0.0
  },
  {
    latitude: 48.3677459,
    longitude: 11.5954557,
    heading: 3.9166610564029725,
    altitude: 0.0
  },
  {
    latitude: 48.3678604,
    longitude: 11.5954675,
    heading: 3.8869466382273004,
    altitude: 0.0
  },
  {
    latitude: 48.3679748,
    longitude: 11.5954792,
    heading: 4.345671254707554,
    altitude: 0.0
  },
  {
    latitude: 48.3682563,
    longitude: 11.5955114,
    heading: 4.345647325589766,
    altitude: 0.0
  },
  {
    latitude: 48.3685378,
    longitude: 11.5955436,
    heading: 4.345623396628753,
    altitude: 0.0
  },
  {
    latitude: 48.3688193,
    longitude: 11.5955758,
    heading: 4.3455994669873235,
    altitude: 0.0
  },
  {
    latitude: 48.3691008,
    longitude: 11.595608,
    heading: 4.345575537649324,
    altitude: 0.0
  },
  {
    latitude: 48.3693823,
    longitude: 11.5956402,
    heading: 4.332107561524083,
    altitude: 0.0
  },
  {
    latitude: 48.3696638,
    longitude: 11.5956723,
    heading: 4.345527678410804,
    altitude: 0.0
  },
  {
    latitude: 48.3699453,
    longitude: 11.5957045,
    heading: 4.34550374870588,
    altitude: 0.0
  },
  {
    latitude: 48.3702268,
    longitude: 11.5957367,
    heading: 4.345479818748174,
    altitude: 0.0
  },
  {
    latitude: 48.3705083,
    longitude: 11.5957689,
    heading: 4.345455888880167,
    altitude: 0.0
  },
  {
    latitude: 48.3707898,
    longitude: 11.5958011,
    heading: 4.345431958973165,
    altitude: 0.0
  },
  {
    latitude: 48.3710713,
    longitude: 11.5958333,
    heading: 4.345408028630402,
    altitude: 0.0
  },
  {
    latitude: 48.3713528,
    longitude: 11.5958655,
    heading: 4.3453840983464715,
    altitude: 0.0
  },
  {
    latitude: 48.3716343,
    longitude: 11.5958977,
    heading: 4.345360167956585,
    altitude: 0.0
  },
  {
    latitude: 48.3719158,
    longitude: 11.5959299,
    heading: 4.345336237313973,
    altitude: 0.0
  },
  {
    latitude: 48.3721973,
    longitude: 11.5959621,
    heading: 4.331868994904482,
    altitude: 0.0
  },
  {
    latitude: 48.3724788,
    longitude: 11.5959942,
    heading: 4.345288376101962,
    altitude: 0.0
  },
  {
    latitude: 48.3727603,
    longitude: 11.5960264,
    heading: 4.345264445239081,
    altitude: 0.0
  },
  {
    latitude: 48.3730418,
    longitude: 11.5960586,
    heading: 4.345240514630746,
    altitude: 0.0
  },
  {
    latitude: 48.3733233,
    longitude: 11.5960908,
    heading: 4.345216583293222,
    altitude: 0.0
  },
  {
    latitude: 48.3736048,
    longitude: 11.596123,
    heading: 4.345192652259129,
    altitude: 0.0
  },
  {
    latitude: 48.3738863,
    longitude: 11.5961552,
    heading: 4.12171990524223,
    altitude: 0.0
  },
  {
    latitude: 48.3741739,
    longitude: 11.5961864,
    heading: 4.107108267399269,
    altitude: 0.0
  },
  {
    latitude: 48.3744616,
    longitude: 11.5962175,
    heading: 4.121673494056267,
    altitude: 0.0
  },
  {
    latitude: 48.3747492,
    longitude: 11.5962487,
    heading: 4.107062019217608,
    altitude: 0.0
  },
  {
    latitude: 48.3750369,
    longitude: 11.5962798,
    heading: 4.121627082314546,
    altitude: 0.0
  },
  {
    latitude: 48.3753245,
    longitude: 11.596311,
    heading: 4.121603880410873,
    altitude: 0.0
  },
  {
    latitude: 48.3756121,
    longitude: 11.5963422,
    heading: 4.106992649957476,
    altitude: 0.0
  },
  {
    latitude: 48.3758998,
    longitude: 11.5963733,
    heading: 4.121557468130732,
    altitude: 0.0
  },
  {
    latitude: 48.3761874,
    longitude: 11.5964045,
    heading: 4.121534265912544,
    altitude: 0.0
  },
  {
    latitude: 48.376475,
    longitude: 11.5964357,
    heading: 4.106923279683372,
    altitude: 0.0
  },
  {
    latitude: 48.3767627,
    longitude: 11.5964668,
    heading: 4.121487852957671,
    altitude: 0.0
  },
  {
    latitude: 48.3770503,
    longitude: 11.596498,
    heading: 4.106877029920213,
    altitude: 0.0
  },
  {
    latitude: 48.377338,
    longitude: 11.5965291,
    heading: 4.12144143958335,
    altitude: 0.0
  },
  {
    latitude: 48.3776256,
    longitude: 11.5965603,
    heading: 4.1214182369316745,
    altitude: 0.0
  },
  {
    latitude: 48.3779132,
    longitude: 11.5965915,
    heading: 4.106807658061825,
    altitude: 0.0
  },
  {
    latitude: 48.3782009,
    longitude: 11.5966226,
    heading: 4.121371822973572,
    altitude: 0.0
  },
  {
    latitude: 48.3784885,
    longitude: 11.5966538,
    heading: 4.1213486196894,
    altitude: 0.0
  },
  {
    latitude: 48.3787761,
    longitude: 11.596685,
    heading: 4.106738285415759,
    altitude: 0.0
  },
  {
    latitude: 48.3790638,
    longitude: 11.5967161,
    heading: 4.121302205192876,
    altitude: 0.0
  },
  {
    latitude: 48.3793514,
    longitude: 11.5967473,
    heading: 4.106692033844979,
    altitude: 0.0
  },
  {
    latitude: 48.3796391,
    longitude: 11.5967784,
    heading: 4.121255790231487,
    altitude: 0.0
  },
  {
    latitude: 48.3799267,
    longitude: 11.5968096,
    heading: 4.424790042359007,
    altitude: 0.0
  },
  {
    latitude: 48.3799885,
    longitude: 11.5968168,
    heading: 4.363568368957544,
    altitude: 0.0
  },
  {
    latitude: 48.3800503,
    longitude: 11.5968239,
    heading: 4.4176593825791315,
    altitude: 0.0
  },
  {
    latitude: 48.3801122,
    longitude: 11.5968311,
    heading: 4.363557806937138,
    altitude: 0.0
  },
  {
    latitude: 48.380174,
    longitude: 11.5968382,
    heading: 4.424768631384666,
    altitude: 0.0
  },
  {
    latitude: 48.3802358,
    longitude: 11.5968454,
    heading: 4.36354725335633,
    altitude: 0.0
  },
  {
    latitude: 48.3802976,
    longitude: 11.5968525,
    heading: 4.424757931201555,
    altitude: 0.0
  },
  {
    latitude: 48.3803594,
    longitude: 11.5968597,
    heading: 4.356514518108838,
    altitude: 0.0
  },
  {
    latitude: 48.3804213,
    longitude: 11.5968668,
    heading: 4.42474722204696,
    altitude: 0.0
  },
  {
    latitude: 48.3804831,
    longitude: 11.596874,
    heading: 4.363526135502013,
    altitude: 0.0
  },
  {
    latitude: 48.3805449,
    longitude: 11.5968811,
    heading: 4.424736520848796,
    altitude: 0.0
  },
  {
    latitude: 48.3806067,
    longitude: 11.5968883,
    heading: 4.363515581859588,
    altitude: 0.0
  },
  {
    latitude: 48.3806685,
    longitude: 11.5968954,
    heading: 4.424725820603214,
    altitude: 0.0
  },
  {
    latitude: 48.3807303,
    longitude: 11.5969026,
    heading: 4.356482896678926,
    altitude: 0.0
  },
  {
    latitude: 48.3807922,
    longitude: 11.5969097,
    heading: 4.4247151116128975,
    altitude: 0.0
  },
  {
    latitude: 48.380854,
    longitude: 11.5969169,
    heading: 4.363494465079384,
    altitude: 0.0
  },
  {
    latitude: 48.3809158,
    longitude: 11.596924,
    heading: 4.424704408698403,
    altitude: 0.0
  },
  {
    latitude: 48.3809776,
    longitude: 11.5969312,
    heading: 4.363483910480568,
    altitude: 0.0
  },
  {
    latitude: 48.3810394,
    longitude: 11.5969383,
    heading: 4.417573886649052,
    altitude: 0.0
  },
  {
    latitude: 48.3811013,
    longitude: 11.5969455,
    heading: 4.3634733463486555,
    altitude: 0.0
  },
  {
    latitude: 48.3811631,
    longitude: 11.5969526,
    heading: 4.424683000017978,
    altitude: 0.0
  },
  {
    latitude: 48.3812249,
    longitude: 11.5969598,
    heading: 5.689355238322321,
    altitude: 0.0
  },
  {
    latitude: 48.3812489,
    longitude: 11.5969634,
    heading: 5.689352578973171,
    altitude: 0.0
  },
  {
    latitude: 48.3812729,
    longitude: 11.596967,
    heading: 5.532306728296135,
    altitude: 0.0
  },
  {
    latitude: 48.3812969,
    longitude: 11.5969705,
    heading: 5.689347247788021,
    altitude: 0.0
  },
  {
    latitude: 48.3813209,
    longitude: 11.5969741,
    heading: 5.689344584441017,
    altitude: 0.0
  },
  {
    latitude: 48.3813449,
    longitude: 11.5969777,
    heading: 5.665888594529861,
    altitude: 0.0
  },
  {
    latitude: 48.381369,
    longitude: 11.5969813,
    heading: 5.5322963486243,
    altitude: 0.0
  },
  {
    latitude: 48.381393,
    longitude: 11.5969848,
    heading: 5.689336582042017,
    altitude: 0.0
  },
  {
    latitude: 48.381417,
    longitude: 11.5969884,
    heading: 5.689333907705247,
    altitude: 0.0
  },
  {
    latitude: 48.381441,
    longitude: 11.596992,
    heading: 5.689331246600261,
    altitude: 0.0
  },
  {
    latitude: 48.381465,
    longitude: 11.5969956,
    heading: 5.53228598150713,
    altitude: 0.0
  },
  {
    latitude: 48.381489,
    longitude: 11.5969991,
    heading: 5.6893259201426645,
    altitude: 0.0
  },
  {
    latitude: 48.381513,
    longitude: 11.5970027,
    heading: 5.689323249047618,
    altitude: 0.0
  },
  {
    latitude: 48.381537,
    longitude: 11.5970063,
    heading: 5.689320588188195,
    altitude: 0.0
  },
  {
    latitude: 48.381561,
    longitude: 11.5970099,
    heading: 5.532275613020943,
    altitude: 0.0
  },
  {
    latitude: 48.381585,
    longitude: 11.5970134,
    heading: 5.6658620433696,
    altitude: 0.0
  },
  {
    latitude: 48.3816091,
    longitude: 11.597017,
    heading: 5.689312585012658,
    altitude: 0.0
  },
  {
    latitude: 48.3816331,
    longitude: 11.5970206,
    heading: 5.689309915160891,
    altitude: 0.0
  },
  {
    latitude: 48.3816571,
    longitude: 11.5970242,
    heading: 5.5322652318444625,
    altitude: 0.0
  },
  {
    latitude: 48.3816811,
    longitude: 11.5970277,
    heading: 5.6893045894360625,
    altitude: 0.0
  },
  {
    latitude: 48.3817051,
    longitude: 11.5970313,
    heading: 5.6893019215787035,
    altitude: 0.0
  },
  {
    latitude: 48.3817291,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817352,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817414,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817475,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817536,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817598,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817659,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.381772,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817782,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817843,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817904,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3817966,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818027,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818088,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.381815,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818211,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818272,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818334,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818395,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818456,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818518,
    longitude: 11.5970349,
    heading: 0,
    altitude: 0.0
  },
  {
    latitude: 48.3818579,
    longitude: 11.5970349,
    heading: 4.263703214468251,
    altitude: 0.0
  },
  {
    latitude: 48.382004,
    longitude: 11.5970513,
    heading: 4.2377879084887695,
    altitude: 0.0
  },
  {
    latitude: 48.3821501,
    longitude: 11.5970676,
    heading: 4.2636788289225365,
    altitude: 0.0
  },
  {
    latitude: 48.3822962,
    longitude: 11.597084,
    heading: 4.23487560298571,
    altitude: 0.0
  },
  {
    latitude: 48.3824424,
    longitude: 11.5971003,
    heading: 4.263654435608601,
    altitude: 0.0
  },
  {
    latitude: 48.3825885,
    longitude: 11.5971167,
    heading: 4.237739422594416,
    altitude: 0.0
  },
  {
    latitude: 48.3827346,
    longitude: 11.597133,
    heading: 4.263630049334381,
    altitude: 0.0
  },
  {
    latitude: 48.3828807,
    longitude: 11.5971494,
    heading: 4.237715184613421,
    altitude: 0.0
  },
  {
    latitude: 48.3830268,
    longitude: 11.5971657,
    heading: 4.263605663730004,
    altitude: 0.0
  },
  {
    latitude: 48.3831729,
    longitude: 11.5971821,
    heading: 4.237690945465715,
    altitude: 0.0
  },
  {
    latitude: 48.383319,
    longitude: 11.5971984,
    heading: 4.260675745772005,
    altitude: 0.0
  },
  {
    latitude: 48.3834652,
    longitude: 11.5972148,
    heading: 4.2376666988304805,
    altitude: 0.0
  },
  {
    latitude: 48.3836113,
    longitude: 11.5972311,
    heading: 4.263556883696424,
    altitude: 0.0
  },
  {
    latitude: 48.3837574,
    longitude: 11.5972475,
    heading: 4.237642459048175,
    altitude: 0.0
  },
  {
    latitude: 48.3839035,
    longitude: 11.5972638,
    heading: 4.263532497663448,
    altitude: 0.0
  },
  {
    latitude: 48.3840496,
    longitude: 11.5972802,
    heading: 4.237618220025979,
    altitude: 0.0
  },
  {
    latitude: 48.3841957,
    longitude: 11.5972965,
    heading: 4.263508111240981,
    altitude: 0.0
  },
  {
    latitude: 48.3843418,
    longitude: 11.5973129,
    heading: 4.23470602932241,
    altitude: 0.0
  },
  {
    latitude: 48.384488,
    longitude: 11.5973292,
    heading: 4.26348371663812,
    altitude: 0.0
  },
  {
    latitude: 48.3846341,
    longitude: 11.5973456,
    heading: 4.237569732572581,
    altitude: 0.0
  },
  {
    latitude: 48.3847802,
    longitude: 11.5973619,
    heading: 4.263459330865828,
    altitude: 0.0
  },
  {
    latitude: 48.3849263,
    longitude: 11.5973783,
    heading: 3.5372770091786947,
    altitude: 0.0
  },
  {
    latitude: 48.3849682,
    longitude: 11.5973822,
    heading: 3.528873324556912,
    altitude: 0.0
  },
  {
    latitude: 48.3850102,
    longitude: 11.5973861,
    heading: 3.537271195851986,
    altitude: 0.0
  },
  {
    latitude: 48.3850521,
    longitude: 11.59739,
    heading: 3.5288675218254184,
    altitude: 0.0
  },
  {
    latitude: 48.3850941,
    longitude: 11.5973939,
    heading: 3.5372653772984677,
    altitude: 0.0
  },
  {
    latitude: 48.385136,
    longitude: 11.5973978,
    heading: 3.5372624747298005,
    altitude: 0.0
  },
  {
    latitude: 48.3851779,
    longitude: 11.5974017,
    heading: 3.5288588223714896,
    altitude: 0.0
  },
  {
    latitude: 48.3852199,
    longitude: 11.5974056,
    heading: 3.537256656164857,
    altitude: 0.0
  },
  {
    latitude: 48.3852618,
    longitude: 11.5974095,
    heading: 3.5288530219584686,
    altitude: 0.0
  },
  {
    latitude: 48.3853038,
    longitude: 11.5974134,
    heading: 3.537250840201807,
    altitude: 0.0
  },
  {
    latitude: 48.3853457,
    longitude: 11.5974173,
    heading: 3.528847218831288,
    altitude: 0.0
  },
  {
    latitude: 48.3853877,
    longitude: 11.5974212,
    heading: 3.5372450248663654,
    altitude: 0.0
  },
  {
    latitude: 48.3854296,
    longitude: 11.5974251,
    heading: 3.537242119039547,
    altitude: 0.0
  },
  {
    latitude: 48.3854715,
    longitude: 11.597429,
    heading: 3.528838518004534,
    altitude: 0.0
  },
  {
    latitude: 48.3855135,
    longitude: 11.5974329,
    heading: 3.5372363039604693,
    altitude: 0.0
  },
  {
    latitude: 48.3855554,
    longitude: 11.5974368,
    heading: 3.5288327140586375,
    altitude: 0.0
  },
  {
    latitude: 48.3855974,
    longitude: 11.5974407,
    heading: 3.5372304882385492,
    altitude: 0.0
  },
  {
    latitude: 48.3856393,
    longitude: 11.5974446,
    heading: 3.5372275830374065,
    altitude: 0.0
  },
  {
    latitude: 48.3856812,
    longitude: 11.5974485,
    heading: 3.5288240134698867,
    altitude: 0.0
  },
  {
    latitude: 48.3857232,
    longitude: 11.5974524,
    heading: 3.5372217675718503,
    altitude: 0.0
  },
  {
    latitude: 48.3857651,
    longitude: 11.5974563,
    heading: 3.5288182126420793,
    altitude: 0.0
  },
  {
    latitude: 48.3858071,
    longitude: 11.5974602,
    heading: 3.5372159504917136,
    altitude: 0.0
  },
  {
    latitude: 48.385849,
    longitude: 11.5974641,
    heading: 0.20402446219281956,
    altitude: 0.0
  },
  {
    latitude: 48.3858863,
    longitude: 11.5974643,
    heading: 0.1017397186431026,
    altitude: 0.0
  },
  {
    latitude: 48.3859237,
    longitude: 11.5974644,
    heading: 0.20402416275913993,
    altitude: 0.0
  },
  {
    latitude: 48.385961,
    longitude: 11.5974646,
    heading: 0.20347849701693121,
    altitude: 0.0
  },
  {
    latitude: 48.3859984,
    longitude: 11.5974648,
    heading: 0.10201225583324458,
    altitude: 0.0
  },
  {
    latitude: 48.3860357,
    longitude: 11.5974649,
    heading: 0.20402371374751738,
    altitude: 0.0
  },
  {
    latitude: 48.386073,
    longitude: 11.5974651,
    heading: 0.20347804927490643,
    altitude: 0.0
  },
  {
    latitude: 48.3861104,
    longitude: 11.5974653,
    heading: 0.10201203133374293,
    altitude: 0.0
  },
  {
    latitude: 48.3861477,
    longitude: 11.5974654,
    heading: 0.20402326319987196,
    altitude: 0.0
  },
  {
    latitude: 48.386185,
    longitude: 11.5974656,
    heading: 0.20347760308078477,
    altitude: 0.0
  },
  {
    latitude: 48.3862224,
    longitude: 11.5974658,
    heading: 0.10201180522028608,
    altitude: 0.0
  },
  {
    latitude: 48.3862597,
    longitude: 11.5974659,
    heading: 0.20347730448077073,
    altitude: 0.0
  },
  {
    latitude: 48.3862971,
    longitude: 11.5974661,
    heading: 0.20402266584733297,
    altitude: 0.0
  },
  {
    latitude: 48.3863344,
    longitude: 11.5974663,
    heading: 0.10201158075477679,
    altitude: 0.0
  },
  {
    latitude: 48.3863717,
    longitude: 11.5974664,
    heading: 0.2034768566674643,
    altitude: 0.0
  },
  {
    latitude: 48.3864091,
    longitude: 11.5974666,
    heading: 0.20402221528047448,
    altitude: 0.0
  },
  {
    latitude: 48.3864464,
    longitude: 11.5974668,
    heading: 0.10201135785899851,
    altitude: 0.0
  },
  {
    latitude: 48.3864837,
    longitude: 11.5974669,
    heading: 0.20347640725276506,
    altitude: 0.0
  },
  {
    latitude: 48.3865211,
    longitude: 11.5974671,
    heading: 0.20402176795732885,
    altitude: 0.0
  },
  {
    latitude: 48.3865584,
    longitude: 11.5974673,
    heading: 0.10173837508767747,
    altitude: 0.0
  },
  {
    latitude: 48.3865958,
    longitude: 11.5974674,
    heading: 0.20402146848573466,
    altitude: 0.0
  },
  {
    latitude: 48.3866331,
    longitude: 11.5974676,
    heading: 0,
    altitude: 0.0
  }
] as const
