import { createApi } from '@reduxjs/toolkit/query/react'
import { client } from 'graphql/client'
import { z } from 'zod'
import { listVehicles } from 'graphql/queries'
import { OnUpdateVehicleSubscriptionVariables, Vehicle } from 'API'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { onUpdateVehicle } from '../graphql/subscriptions'
import { mockSubscription, mockVehicle, registerVehiclesDevTools } from '../../mocks/utils'
import { GenericRecord, denormalizeListWithVin } from './utils'
import { WAYPOINTS } from '../../mocks/data/constants'
import { store } from 'store/setup'
import { ExtendedVehicleSchema } from 'helper/zod'
import { ExtendedVehicle } from 'helper/types'
import { VehicleSchema } from 'APIzod'
import { errorHandler } from './errors'
import { setConnectivityLatency } from 'store/slices/appSlice'

/**
 * The reason for the decision to calculate the latency here in the FE instead of in the VAS,
 * was the availability of the information in the FE while avoiding the additional effort
 * needed to extend the vehicle model with the calculated latency property.
 *
 * This should be expected to be calculated in the VAS whenever the latency calculation
 * feature complexity increases.
 */
const getDifferenceInMilliseconds = (
  latestVehicleUpdateDateTime: Date,
  updatedAt: Date
): number => {
  return updatedAt.getTime() - latestVehicleUpdateDateTime.getTime()
}

const updateConnectivityLatency = (vehicle: Vehicle) => {
  const state = store.getState()
  const newConnectivityLatency = {
    ...state.app.connectivityLatency,
    [vehicle.vin]:
      vehicle.latestVehicleUpdate !== null && vehicle.latestVehicleUpdate !== undefined
        ? getDifferenceInMilliseconds(
            new Date(vehicle.latestVehicleUpdate),
            new Date(vehicle.updatedAt)
          )
        : undefined
  }
  store.dispatch(setConnectivityLatency(newConnectivityLatency))
}

const transformAlertsWithVin = (vehicle: Vehicle) => {
  return { ...vehicle, alerts: vehicle.alerts.map((_) => ({ ..._, vin: vehicle.vin })) }
}
const mapToExtendedVehicles = (vehicleList: Vehicle[]) => {
  return z.array(ExtendedVehicleSchema).parse(vehicleList.map(transformAlertsWithVin))
}

const listVehiclesQueryFn = async () => {
  try {
    const { data } = await client.graphql({ query: listVehicles })
    const extendedVehicle = mapToExtendedVehicles(data.listVehicles)
    return {
      data: denormalizeListWithVin(extendedVehicle)
    }
  } catch (err) {
    throw errorHandler('Unexpected error occurred while fetching the vehicles', err)
  }
}

export const vehiclesApi = createApi({
  reducerPath: 'vehicles',
  baseQuery: graphqlRequestBaseQuery({ url: '/graphql' }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    listVehicles: builder.query<GenericRecord<ExtendedVehicle>, string>({
      queryFn: listVehiclesQueryFn
    }),
    onUpdateVehicleSubscription: builder.query<
      GenericRecord<ExtendedVehicle>,
      OnUpdateVehicleSubscriptionVariables
    >({
      queryFn: listVehiclesQueryFn,
      onCacheEntryAdded: async (_, { cacheDataLoaded, cacheEntryRemoved, updateCachedData }) => {
        const updateCache = (data: Vehicle) => {
          const vehicle = ExtendedVehicleSchema.parse(transformAlertsWithVin(data))
          updateCachedData((draft) => {
            draft[vehicle.vin] = vehicle
          })
          updateConnectivityLatency(vehicle)
        }

        await cacheDataLoaded
        if (import.meta.env.DEV) {
          mockSubscription({
            maxIterations: WAYPOINTS.length,
            mockFn: mockVehicle,
            callback: (data) => {
              const updatedVehicle = VehicleSchema.parse(data.onUpdateVehicle)
              updateCache(updatedVehicle)
            }
          })
          registerVehiclesDevTools((data) => {
            updateCache(data.onUpdateVehicle)
          })
          await cacheEntryRemoved
        } else {
          const connection = client.graphql({ query: onUpdateVehicle }).subscribe({
            next: ({ data }) => {
              if (!data.onUpdateVehicle) {
                console.error(
                  '[onUpdateVehicleSubscription]: Please make sure all the attributes are returned in the mutation'
                )
              }
              updateCache(data.onUpdateVehicle)
            },
            error: (error) => console.warn(error)
          })
          cacheEntryRemoved
            .then(() => {
              connection.unsubscribe()
            })
            .catch((error) => console.error(error))
        }
      }
    })
  })
})

export const {
  useListVehiclesQuery,
  useLazyListVehiclesQuery,
  useOnUpdateVehicleSubscriptionQuery
} = vehiclesApi
